import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LoadingView } from '../LoadingView';
import { BaseModal } from './BaseModal';

import { globalActivitySelector, propertyActivitySelector } from '_app/store/activity/selector';
import { FlexCol, FlexRow, HR, Label } from '..';
import { fetchActivityAction } from '_app/store/activity/action';
import moment from 'moment';

export const RecentActivitiesModal = ({ visible, mlsnum = null, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const { loading, items } = useSelector(!mlsnum ? globalActivitySelector : propertyActivitySelector);
  useEffect(() => {
    dispatch(fetchActivityAction(mlsnum));
  }, [dispatch, mlsnum, visible]);

  return (
    <BaseModal visible={visible} onClose={onClose}>
      <FlexCol className="bg-white max-w-screen-sm w-full pb-2 m-auto h-full relative">
        <FlexCol className="px-2 sm:px-4">
          <FlexRow className="items-center">
            <FontAwesomeIcon icon={['fas', 'share-alt']} className="text-primary2" size="sm" />
            <Label label="Recent Activities" className="text-primary2 text-xl ml-2 mb-1.5 uppercase pt-4 py-2" />
          </FlexRow>
          <HR className="bg-blue-300" />
        </FlexCol>
        <FlexCol className="overflow-y-auto overflow-hidden flex-1 px-1 sm:px-4 py-2">
          {items.map((item, index) => (
            <FlexRow key={index} className="py-2 items-center border-b border-gray-100 row-container mb-1">
              <FontAwesomeIcon
                icon={['fas', item.icon === 'pencil-square' ? 'pen-square' : item.icon]}
                color={item.icon_color === 'white' ? 'gray' : item.icon_color}
                size="lg"
              />
              <FlexCol className="ml-4">
                <div
                  className="font-primary"
                  dangerouslySetInnerHTML={{ __html: item.actor && item.activity_type.includes('Rated') ? `${item.actor} ${item.text}` : item.text }}
                />
                <div className="italic text-gray-400 text-sm">{moment(item.datetime).fromNow()}</div>
              </FlexCol>
            </FlexRow>
          ))}
        </FlexCol>
      </FlexCol>
      {loading && <LoadingView />}
      <button className="top-2 right-2 md:left-2 fixed bg-gray-200 text-black text-center py-1 rounded font-primary-bold w-20" onClick={onClose}>
        back
      </button>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .row-container a {
              color: #0f5688;
              font-weight: 600;
            }
          `,
        }}
      />
    </BaseModal>
  );
};
