import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Logo } from './Logo';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '_app/store/user/selector';

const menu_items = [
  { label: 'Neighborhoods', href: 'https://www.houstonproperties.com/houston-neighborhoods?np=1', target: '_blank' },
  { label: 'Value My Current Home', href: 'https://my.houstonproperties.com/home-valuation/55156FEv/activity/', target: '_blank' },
];

export const MobileMenu = ({ visible = false, onClose = () => { } }) => {
  const dispatch = useDispatch();
  const { lead_owner } = useSelector(userSelector);
  const handleAction = (action) => {
    onClose();
    if (action) {
      dispatch(action());
    }
  };

  const user = useSelector(userSelector);
  const isStaff = user && !!user.staff_session;

  return (
    <div className={`absolute z-top w-full h-full flex-col flex sm:hidden ${!visible ? 'hidden' : ''}`}>
      <div className="bg-white flex w-full flex-col items-end">
        <div className="flex flex-row justify-between items-center w-full p-4">
          <div onClick={onClose}>
            <Logo />
          </div>
          <div className="p-4 cursor-pointer" onClick={onClose}>
            <FontAwesomeIcon icon={['fas', 'times']} className="text-2xl text-primary1" />
          </div>
        </div>
        <ul className="flex-1 flex-col w-full justify-end items-center p-4 pb-0">
          {menu_items.map(({ label, href, action, target }) => (
            <li
              className="px-2 py-4 hover:bg-primary2 cursor-pointer border-b font-primary-bold text-primary1"
              key={label}
              onClick={() => handleAction(action)}>
              <Link to={{ pathname: href }} target={target}>
                {label === 'owner' ? (lead_owner ? `Ask ${lead_owner.name}` : '') : label}
              </Link>
            </li>
          ))}
          {isStaff && !!user.profile_url && (
            <li className="px-2 py-4 hover:bg-primary2 cursor-pointer border-b font-primary-bold text-yellow-600">
              <a href={user.profile_url} rel="noreferrer noopener" target="_blank">
                Edit {user.name} {user.surname}
              </a>
            </li>
          )}
        </ul>
      </div>
      <div
        className="flex-1 h-full bg-black bg-opacity-30"
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}
      />
    </div>
  );
};
