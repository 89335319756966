export const SEARCH_ORDERS = [
  { label: 'Address', value: 'address', enabled: true },
  { label: 'Price - Descending', value: 'price_desc', enabled: true },
  { label: 'Price - Ascending', value: 'price_asc', enabled: true },
  { label: 'Subdivision', value: 'subdivision', enabled: true },
  { label: 'Key Map', value: 'key_map', enabled: true },
  { label: 'Lot Size - Descending', value: 'lot_size_desc', enabled: true },
  { label: 'MLS Area', value: 'mls_area', enabled: true },
  { label: 'Square Feet', value: 'square_feet', enabled: true },
  { label: 'Year Built', value: 'year', enabled: true },
  { label: 'Newest', value: 'newest', enabled: true },
];

export const SEARCH_CHECKLIST = [
  { label: 'Single Family', value: false, key: 'single' },
  { label: 'Multi Family', value: false, key: 'multi' },
  { label: 'Townhome', value: false, key: 'townhome' },
  { label: 'Lots', value: false, key: 'lots' },
  { label: 'Highrise Condo', value: false, key: 'highrise' },
  { label: 'Acreage', value: false, key: 'acreage' },
];

export const RADIO_LIST = [
  { label: 'N/A', value: 0 },
  { label: 'Yes', value: 1 },
  { label: 'No', value: 2 },
];
