import APIService from '_app/services/api';
import { all, fork, put, take } from 'redux-saga/effects';

import { FAIL, START, SUCCESS } from '../common';
import { FETCH_ACTIVITY, FETCH_UPCOMINGS, RESERVE_OPEN_HOUSE } from './action';
import Utils from '_app/utils';
import { showInfoToast } from '_app/utils/toast';

function* fetchActivity() {
  while (true) {
    const { payload } = yield take(FETCH_ACTIVITY + START);
    try {
      const { data } = yield APIService.get('/properties/recent-activities/', { params: payload ? { mlsnum: payload } : null });
      yield put({ type: FETCH_ACTIVITY + SUCCESS, payload: { data: data.activities, mlsnum: payload } });
    } catch (error) {
      yield put({ type: FETCH_ACTIVITY + FAIL, payload: { data: Utils.getError(error), mlsnum: payload } });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* fetchUpcoming() {
  while (true) {
    const { payload } = yield take(FETCH_UPCOMINGS + START);
    try {
      const { data } = yield APIService.get('/properties/upcoming-open-houses/', { params: payload ? { mlsnum: payload } : null });
      yield put({ type: FETCH_UPCOMINGS + SUCCESS, payload: { data: data.open_houses, mlsnum: payload } });
    } catch (error) {
      yield put({ type: FETCH_UPCOMINGS + FAIL, payload: { data: Utils.getError(error), mlsnum: payload } });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* reserveOpenHouse() {
  while (true) {
    const { payload } = yield take(RESERVE_OPEN_HOUSE + START);
    try {
      const { data } = yield APIService.post(`/properties/attend-open-house/${payload.id}/`);
      yield put({ type: RESERVE_OPEN_HOUSE + SUCCESS, payload: data });
      yield put({ type: FETCH_UPCOMINGS + START, payload: payload.mlsnum });
      showInfoToast('Successfully sent a reserve request.');
    } catch (error) {
      yield put({ type: RESERVE_OPEN_HOUSE + FAIL, payload: Utils.getError(error) });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

export default function* sagas() {
  yield all([fork(fetchActivity), fork(fetchUpcoming), fork(reserveOpenHouse)]);
}
