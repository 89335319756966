import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { LoadingView } from '../LoadingView';
import { BaseModal } from './BaseModal';
import { Button, FlexCol, FlexRow, HR, Label, PhoneNumberInput } from '..';
import { userSelector } from '_app/store/user/selector';
import { askPropertyTour, initAskPropertyAction } from '_app/store/property/action';
import { getPropertyAskSelector } from '_app/store/property/selector';

export const PropertyTourActionModal = ({ visible, mlsnum, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { loading, success } = useSelector(getPropertyAskSelector);
  const [phone, setPhone] = useState(user.phone || '');
  const [dateInfo, setDateInfo] = useState('');
  useEffect(() => {
    if (visible) {
      dispatch(initAskPropertyAction());
      setPhone(user.phone || '');
      setDateInfo('');
    }
  }, [visible]);

  useEffect(() => {
    if (!loading && success) {
      onClose();
    }
  }, [loading, success]);

  const disabled = !isValidPhoneNumber(phone, 'US') || !dateInfo.trim();

  const onSubmit = () => {
    dispatch(askPropertyTour({ mlsnum, phone, date: dateInfo }));
  };

  return (
    <BaseModal visible={visible} onClose={onClose} overlayClassName="items-center" className="my-auto w-screen max-w-screen-sm px-2">
      <FlexCol className="bg-white w-full pb-2 rounded">
        <FlexRow className="p-4 items-center justify-between flex-shrink-0">
          <FlexRow className="items-center">
            <Label label="Schedule A Tour?" className="text-primary5 text-lg font-primary-bold" />
          </FlexRow>
          <FontAwesomeIcon icon={['fas', 'times']} className="text-lg cursor-pointer text-primary5 mr-1" onClick={onClose} />
        </FlexRow>
        <HR className="flex-shrink-0" />
        <FlexCol className="px-5 py-6">
          <FlexCol>
            <Label label="1. Best Phone # For You To Coordinate:" className="text-lg font-primary text-primary3" />
            <PhoneNumberInput value={phone} onChange={setPhone} />
          </FlexCol>
          <FlexCol className="mt-10">
            <Label label="2. What Dates/Times Work Best For You:" className="text-lg font-primary text-primary3" />
            <textarea
              className={`border border-primary1 rounded h-20 outline-none px-2 py-1 mx-4 mt-2`}
              value={dateInfo}
              onChange={(e) => setDateInfo(e.target.value)}
            />
          </FlexCol>
          <FlexRow className="mt-8 justify-end w-full">
            <Button
              label="Submit"
              className={`text-white px-8 py-1 ${disabled ? 'bg-gray-500' : 'bg-primary2'}`}
              onClick={() => !disabled && onSubmit()}
            />
          </FlexRow>
        </FlexCol>
      </FlexCol>
      {loading && <LoadingView />}
    </BaseModal>
  );
};
