import React from 'react';
import _ from 'lodash';

import { FlexCol } from '../FlexCol';
import { FlexRow } from '../FlexRow';
import { Label } from '../Label';
import { OrNotSelector } from '.';
import { useDispatch } from 'react-redux';
import { handleSearchOptionChangeAction } from '_app/store/property/action';

const getSelectValues = (id, item) => {
  const select = document.getElementById(id);
  let result = [];
  let options = select && select.options;
  let opt;

  for (var i = 0, iLen = options.length; i < iLen; i++) {
    opt = options[i];
    if (opt.selected) {
      if (opt.getAttribute('id')) {
        const subItem = item.choices.find((sItem) => sItem.optgroup === opt.id);
        if (subItem && subItem.choices) {
          result.push(...subItem.choices.map(({ value }) => value));
        }
      } else if (opt.value) {
        result.push(opt.value);
      }
    }
  }
  return result.map((value) => value);
};

const generateNewChoiceItem = (item) => {
  const { name: id } = item;
  const ids = getSelectValues(id, item);
  const newValues = _.uniq([...ids]);
  return { ...item, value: newValues };
};

const ALL_SELECTOR = { optgroup: 'All', choices: [], value: 'all' };

export const SchoolInfoRow = ({ section, className = '' }) => {
  const dispatch = useDispatch();
  const onChangeValue = (item) => {
    const newItem = generateNewChoiceItem(item);
    const newValues = newItem.value.filter((sItem) => !!sItem);
    dispatch(handleSearchOptionChangeAction({ ...newItem, value: newValues.length ? newValues : null }));
  };
  return (
    <FlexCol className={className + ' my-2 mx-4 items-center'}>
      <FlexRow className="items-start w-full mb-2">
        <Label label={section.label + ':'} className="text-left font-primary-bold" />
      </FlexRow>
      <FlexCol className="w-full">
        {section.items.map((item, index) => {
          if (!item.name.includes('_condition')) {
            return (
              <FlexCol key={index} className="border mb-2 rounded sm:rounded-lg shadow overflow-y-auto">
                <select
                  id={item.name}
                  className="outline-none w-full h-6 sm:h-64 rounded-none px-2 sm:px-0"
                  multiple
                  value={item.value || ['All']}
                  onChange={() => onChangeValue(item)}>
                  {[ALL_SELECTOR, ...item.choices].map(({ optgroup, choices }, sIndex) => (
                    <React.Fragment key={sIndex}>
                      <option
                        key={sIndex}
                        id={optgroup}
                        value={optgroup}
                        className="px-2 pt-1.5 border-b text-sm font-primary-bold text-gray-900 outline-none">
                        {optgroup}
                      </option>
                      {choices.map(({ label, value }, pIndex) => (
                        <option key={pIndex} value={value} className="pl-5 pt-1.5 border-b text-sm font-primary-bold text-gray-600 outline-none">
                          {label}
                        </option>
                      ))}
                    </React.Fragment>
                  ))}
                </select>
              </FlexCol>
            );
          }
          return (
            <FlexRow key={index} className="w-full justify-end">
              <OrNotSelector key={index} item={item} />
            </FlexRow>
          );
        })}
      </FlexCol>
    </FlexCol>
  );
};
