import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FlexRow } from '..';
import { FlexCol } from '../FlexCol';
import { Label } from '../Label';
import { AdditionalInfoSectionForm } from './AdditionalInfoSectionForm';
import { LocationSectionForm } from './LocationSectionForm';
import { PropertyInfoSectionForm } from './PropertyInfoSectionForm';
import { RentalsInfoSectionForm } from './RentalsInfoSectionForm';
import { SchoolInfoSectionForm } from './SchoolInfoSectionForm';
import { SearchSectionForm } from './SearchSectionForm';
import { CondoDetailSectionForm } from './CondoDetailSectionForm';

export const SectionWrapper = ({ title, data, advanced = false }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <FlexCol className="w-full mb-4 border rounded overflow-hidden">
      <FlexRow className={'p-1 sm:p-2 bg-primary2 w-full justify-between items-center cursor-pointer z-10'} onClick={() => setCollapsed(!collapsed)}>
        <Label label={title} className="text-lg text-white" />
        <div className="px-2">
          <FontAwesomeIcon
            icon={['fas', 'chevron-down']}
            className={`text-white transition-transform transform ${collapsed ? '-rotate-90' : ''}`}
            size="sm"
          />
        </div>
      </FlexRow>
      <div className={`${collapsed ? 'hidden' : 'block'}`}>
        <FlexCol className="px-1 py-2">
          {title === 'Search' && <SearchSectionForm data={data} />}
          {title === 'Property Info' && <PropertyInfoSectionForm advanced={advanced} data={data} />}
          {title === 'Location' && <LocationSectionForm advanced={advanced} data={data} />}
          {title === 'School Information' && <SchoolInfoSectionForm advanced={advanced} data={data} />}
          {title === 'Additional Options' && <AdditionalInfoSectionForm advanced={advanced} data={data} />}
          {title === 'Condo Details' && <CondoDetailSectionForm advanced={advanced} data={data} />}
          {title === 'Rentals' && <RentalsInfoSectionForm advanced={advanced} data={data} />}
        </FlexCol>
      </div>
    </FlexCol>
  );
};
