import React from 'react';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';

export const PhoneNumberInput = ({ value = '', onChange = () => {} }) => {
  const formatedPhoneNumber = parsePhoneNumber(value || '', 'US') || {};
  return (
    <PhoneInput
      className={`border-b border-primary1 px-2 mx-4 py-1`}
      numberInputProps={{ className: 'outline-none' }}
      placeholder="Phone"
      defaultCountry="US"
      value={formatedPhoneNumber.number || ''}
      onChange={(value) => onChange(value || '')}
    />
  );
};
