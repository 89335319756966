import React from 'react';

import { FlexCol } from '../FlexCol';
import { AdditionalInfoRow } from './AdditionalInfoRow';

export const AdditionalInfoSectionForm = ({ data = {}, advanced = false }) => {
  const list = data.filter((item) => (advanced ? item : !item.advanced));
  return (
    <FlexCol className="w-full sm:grid sm:grid-cols-2 lg:grid-cols-3">
      {list.map((section, index) => {
        return (
          <FlexCol key={index} className="w-full">
            <AdditionalInfoRow section={section} />
          </FlexCol>
        );
      })}
    </FlexCol>
  );
};
