import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  ChatPannel,
  FlexCol,
  FlexRow,
  HR,
  Label,
  MultiImageSlider,
  PropertyDetailMap,
  PropertyDetailStreetView,
  SchoolTable,
  LoadingView,
  NotificationComponent,
  Button,
  PropertyInfoActions,
} from '_app/components';
import NotFound from '../pages/NotFound';
import { FullScreenImageModal, RecentActivitiesModal } from '_app/components/modals';

import Utils from '_app/utils';
import { ReactComponent as LIKE_ICON } from '_app/assets/images/like.svg';
import { ReactComponent as DISLIKE_ICON } from '_app/assets/images/dislike.svg';

import { fetchPropertyInfoAction, likePropertyAction } from '_app/store/property/action';
import { fetchChatAction } from '_app/store/chat/action';
import { getSelectedPropertySelector } from '_app/store/property/selector';
import { fetchUpcomingsAction, reserveOpenHouseAction } from '_app/store/activity/action';
import { propertyUpcomingSelector } from '_app/store/activity/selector';
import moment from 'moment';
import { hasNewPrice, isManuallyAdded, isNewProperty } from '_app/utils/propertyParser';
import { userSelector } from '_app/store/user/selector';

const PropertyDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [showFullImage, setShowFullImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const { item, loading } = useSelector(getSelectedPropertySelector);
  const { items: events } = useSelector(propertyUpcomingSelector);
  const user = useSelector(userSelector);
  const [openActivity, setOpenActivity] = useState(false);

  const isStaff = user && !!user.staff_session;

  useEffect(() => {
    if (params.propertyId) {
      dispatch(fetchPropertyInfoAction(params.propertyId));
      dispatch(fetchChatAction(params.propertyId));
      dispatch(fetchUpcomingsAction(params.propertyId));
    }
  }, [dispatch]);

  if (!item && !loading) {
    return <NotFound />;
  }

  const onLike = (item) => {
    const notification = (
      <NotificationComponent
        text={`${item.address} added to Favs. `}
        undoAction={() => {
          dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating, notification }));
        }}
      />
    );
    dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating === 1 ? 0 : 1, notification }));
  };

  const onDisLike = (item) => {
    const notification = (
      <NotificationComponent
        text={`${item.address} added to Reject. `}
        undoAction={() => {
          dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating, notification }));
        }}
      />
    );
    dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating === -1 ? 0 : -1, notification }));
  };

  return (
    <div className="flex flex-1 overflow-hidden">
      {!!item && (
        <FlexCol className="bg-white w-full flex-1 items-center pb-20 overflow-y-auto relative">
          <MultiImageSlider
            className="w-full z-10"
            images={item.photos}
            onClick={(selectedImage) => {
              setSelectedImage(selectedImage || 0);
              setShowFullImage(true);
            }}
          />
          <FlexRow className="absolute top-2 left-2 sm:top-4 sm:left-4 items-center z-20">
            {isNewProperty(item) && (
              <Label
                className="bg-yellow-600 shadow-inner text-white rounded-full mx-1 px-6 pt-0.5 pb-0 font-primary-bold text-sm sm:text-lg"
                label="New"
              />
            )}
            {hasNewPrice(item) && (
              <Label
                className="bg-yellow-400 shadow-inner text-white rounded-full mx-1 px-6 pt-0.5 pb-0 font-primary-bold text-sm sm:text-lg"
                label="New Price"
              />
            )}
            {isManuallyAdded(item) && (
              <Label
                className="bg-purple-600 shadow-inner text-white rounded-full mx-1 px-6 pt-0.5 pb-0 font-primary-bold text-sm sm:text-lg"
                label="Added"
              />
            )}
          </FlexRow>
          <FlexCol className="w-full md:max-w-3xl lg:max-w-4xl z-20 relative px-3 sm:px-3 md:px-0">
            <FlexRow className="absolute -top-12">
              <FlexCol className="bg-black bg-opacity-80 text-white text-3xl font-primary h-12 px-3 inline-block align-middle w-auto justify-center pr-8">
                <span>{`$${item.price.toLocaleString()}`}</span>
              </FlexCol>
            </FlexRow>
            <FlexRow className="absolute bg-black bg-opacity-80 -top-12 right-3 md:right-0 border-primary2">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onLike(item);
                }}
                className={`cursor-pointer flex justify-center items-center w-12 h-12 text-white transition duration-500 ease-in-out hover:bg-primary-red hover:bg-opacity-80 ${
                  item.rating === 1 ? 'bg-primary-red bg-opacity-70' : 'bg-black bg-opacity-40'
                }`}>
                <LIKE_ICON width={32} height={32} fill="white" />
              </div>
              <div className="w-px h-12 bg-white bg-opacity-20" />
              <div
                className={`cursor-pointer flex justify-center items-center w-12 h-12  hover:bg-primary2 text-white ${
                  item.rating === -1 ? 'bg-primary2' : 'bg-black bg-opacity-40'
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  onDisLike(item);
                }}>
                <DISLIKE_ICON width={24} fill="white" />
              </div>
            </FlexRow>
            <FlexRow className="px-3 my-4 justify-around">
              <PropertyInfoActions mlsnum={item.mlsnum} />
            </FlexRow>
            <HR />
            <FlexCol className="py-8 font-primary-bold text-primary2 text-xl">
              <div className="">{item.address}</div>
              <div className="">
                {item.city}, {item.zip}
                <Link to="#" className="mr-2 ml-4 font-primary-bold text-primary2 text-xl">
                  Directions
                </Link>
                <span className="text-primary2">{'●'}</span>
                <Link to="#" className="ml-2 font-primary-bold text-xl text-primary5" onClick={() => setOpenActivity(true)}>
                  Recent Activities
                </Link>
              </div>
            </FlexCol>
            <FlexRow className="p-4 border bg-gray-50">
              <FlexCol className="flex-1">
                {!!item.bedrooms && (
                  <FlexRow>
                    <Label label={item.bedrooms} className="mr-2 font-primary-bold text-base leading-5" />
                    <Label label={`Bed${item.bedrooms > 1 ? 's' : ''}`} className="font-primary text-sm pt-px" />
                  </FlexRow>
                )}
                {!!item.full_bathrooms && (
                  <FlexRow>
                    <Label label={item.full_bathrooms} className="mr-2 font-primary-bold text-base leading-5" />
                    <Label label={`Full Bath${item.full_bathrooms > 1 ? 's' : ''}`} className="font-primary text-sm pt-px" />
                  </FlexRow>
                )}
                {!!item.half_bathrooms && (
                  <FlexRow>
                    <Label label={item.half_bathrooms} className="mr-2 font-primary-bold text-base leading-5" />
                    <Label label={`Half bath${item.half_bathrooms > 1 ? 's' : ''}`} className="font-primary text-sm pt-px" />
                  </FlexRow>
                )}
              </FlexCol>
              <FlexCol className="flex-1">
                {!!item.building_size && (
                  <FlexRow>
                    <Label label={item.building_size.toLocaleString()} className="mr-2 font-primary-bold text-base leading-5" />
                    <Label label="Building Sqft." className="font-primary text-sm pt-px" />
                  </FlexRow>
                )}
                {!!item.lot_size && (
                  <FlexRow>
                    <Label label={item.lot_size.toLocaleString()} className="mr-2 font-primary-bold text-base leading-5" />
                    <Label label="Lot Sqft." className="font-primary text-sm pt-px" />
                  </FlexRow>
                )}
                <FlexRow>
                  <Label label={item.property_type} className="mr-2 font-primary text-sm pt-px leading-5" />
                </FlexRow>
              </FlexCol>
            </FlexRow>
            <FlexCol className="mt-8 mb-4 h-96">
              <Label label="View Your Neighbors" className="font-primary-bold text-primary2 text-xl my-2" />
              <PropertyDetailMap item={item} />
            </FlexCol>
            <FlexCol className="my-4 h-96">
              <Label label="Street View" className="font-primary-bold text-primary2 text-xl my-2" />
              <PropertyDetailStreetView item={item} />
            </FlexCol>
            <HR />
            <div className="mt-12">
              <Label label="General Description" className="font-primary-bold text-primary2 text-xl" />
              <Label label={`${isStaff ? `${item.mlsnum} ` : ''} ${item.public_description}`} className="text-base leading-8 text-gray-600" />
            </div>
            {Utils.generatePropertyGeneralRows(item).map(({ value, label }, index) => (
              <div key={index} className="">
                <HR className="my-3" />
                <FlexRow>
                  <Label label={label} className="font-primary-bold text-base flex-1" />
                  <Label label={value} className="text-base flex-1 font-primary" />
                </FlexRow>
              </div>
            ))}
            <div className="mt-12">
              <Label label="Taxes & Fees" className="font-primary-bold text-primary2 text-xl" />
            </div>
            {Utils.generatePropertyTaxRows(item).map(({ value, label, isLink }, index) => (
              <div key={index} className="">
                {!isLink && <HR className="my-3" />}
                <FlexRow className={isLink ? 'justify-center mt-4' : ''}>
                  {isLink && (
                    <a href={value} rel="noreferrer" target="_blank">
                      <Label label={label} className="font-primary-bold text-base flex-1 text-primary5" />
                    </a>
                  )}
                  {!isLink && (
                    <>
                      <Label label={label} className="font-primary-bold text-base flex-1" />
                      <Label label={value} className="text-base flex-1" />
                    </>
                  )}
                </FlexRow>
              </div>
            ))}
            <div className="mt-12">
              <Label label="Room/Lot Size" className="font-primary-bold text-primary2 text-xl" />
            </div>
            {Utils.generatePropertyRoomRows(item).map(({ value, label }, index) => (
              <div key={index} className="">
                <HR className="my-3" />
                <FlexRow>
                  <Label label={label} className="font-primary-bold text-base flex-1" />
                  <Label label={value} className="text-base flex-1" />
                </FlexRow>
              </div>
            ))}
            <div className="mt-12">
              <Label label="Interior Features" className="font-primary-bold text-primary2 text-xl" />
            </div>
            {Utils.generatePropertyInteriorRows(item).map(({ value, label }, index) => (
              <div key={index} className="">
                <HR className="my-3" />
                <FlexRow>
                  <Label label={label} className="font-primary-bold text-base flex-1" />
                  <Label label={value} className="text-base flex-1" />
                </FlexRow>
              </div>
            ))}
            <div className="mt-12">
              <Label label="Exterior Features" className="font-primary-bold text-primary2 text-xl" />
            </div>
            {Utils.generatePropertyExteriorRows(item).map(({ value, label }, index) => (
              <div key={index} className="">
                <HR className="my-3" />
                <FlexRow>
                  <Label label={label} className="font-primary-bold text-base flex-1" />
                  <Label label={value} className="text-base flex-1" />
                </FlexRow>
              </div>
            ))}
            <SchoolTable property={item} />
            {!!events.length && (
              <FlexCol className="mt-12 mb-4">
                <Label label="Upcoming Open Houses" className="font-primary-bold text-primary2 text-xl mb-2" />
                {events.map((event, index) => (
                  <FlexCol key={index}>
                    <Label
                      className="text-primary2 mb-1"
                      label={`${moment(event.datetime_start).format('dddd, MMM DD')} (${moment(event.datetime_start).format('hh:mm A')} - ${moment(
                        event.datetime_end,
                      ).format('hh:mm A')})`}
                    />
                  </FlexCol>
                ))}
                {events[0] && events[0].show_reserve_spot && (
                  <FlexRow className="mt-2">
                    <Label label="Would you like to attend this open house?" className="text-base sm:text-lg text-primary4 mb-1" />
                    <Button
                      label="Yes"
                      className="bg-primary5 border border-primary4 text-primary4 px-5 ml-2 py-0"
                      onClick={() => dispatch(reserveOpenHouseAction(events[0]))}
                    />
                  </FlexRow>
                )}
              </FlexCol>
            )}
            <HR />
          </FlexCol>
        </FlexCol>
      )}
      {showFullImage && (
        <FullScreenImageModal visible={showFullImage} slideIndex={selectedImage} images={item.photos} onClose={() => setShowFullImage(false)} />
      )}
      <RecentActivitiesModal visible={openActivity} mlsnum={params.propertyId} onClose={() => setOpenActivity(false)} />
      <ChatPannel />
      {loading && <LoadingView />}
    </div>
  );
};

export default PropertyDetail;
