import React from 'react';
import { FlexRow } from './FlexRow';
import { Label } from './Label';

export const RadioBox = ({ checked, label = '', className = '', labelClassName = '', inputClassName = '', onClick = () => {} }) => (
  <FlexRow className={'flex-1 items-center justify-center cursor-pointer ' + className} onClick={onClick}>
    {!!label && <Label label={label} className={labelClassName} />}
    <div checked={checked} className={'ml-2 w-4 h-4 cursor-pointer flex p-0.5 border-primary2 border rounded-full ' + inputClassName}>
      {checked && <span className="rounded-full w-full h-full bg-primary2" />}
    </div>
  </FlexRow>
);
