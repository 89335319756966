import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from './Button';
import { FlexCol } from './FlexCol';
import { SectionWrapper } from './SeachForms';

import { searchOptionSelector, simpleSearchFormSelector } from '_app/store/property/selector';
import { cancelSearchFormAction, resetSearchFormAction, submitSearchFormAction, toggleSearchFilterAction } from '_app/store/property/action';
import { ConfirmModal } from './modals';
import { LoadingView } from '.';
import { Link } from 'react-router-dom';
import { publicIdSelector } from '_app/store/user/selector';

const condoSectionFilter = (section, searchSection) => {
  if (section.title === 'Condo Details') {
    const propertyTypeItem = searchSection.data.find((item) => item.name === 'search_prop_type');
    if (propertyTypeItem) {
      const condoOption = (propertyTypeItem.choices && propertyTypeItem.choices.find((sItem) => sItem.label === 'Highrise Condo')) || {};
      return propertyTypeItem.value && propertyTypeItem.value.includes(condoOption.value);
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const rentalSectionFilter = (section, searchSection) => {
  if (section.title === 'Rentals') {
    const propertyTypeItem = searchSection.data.find((item) => item.name === 'search_sale_type');
    if (propertyTypeItem) {
      const rentOption = (propertyTypeItem.choices && propertyTypeItem.choices.find((sItem) => sItem.label === 'Rent')) || {};
      return propertyTypeItem.value && propertyTypeItem.value.includes(rentOption.value);
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const SearchPanel = () => {
  const dispatch = useDispatch();
  const publicId = useSelector(publicIdSelector);
  const { sections, loading } = useSelector(searchOptionSelector);
  const simpleSearch = useSelector(simpleSearchFormSelector);
  const [showResetConfirm, setShowResetConfirm] = useState(false);
  const onConfirmReset = () => {
    setShowResetConfirm(false);
    dispatch(resetSearchFormAction());
  };
  const onCancel = () => {
    dispatch(cancelSearchFormAction());
    dispatch(toggleSearchFilterAction());
  };

  const searchSection = sections.find((sItem) => sItem.title === 'Search');

  return (
    <div className="h-full flex flex-col w-full bg-white font-primary relative">
      <div className="relative flex-1 overflow-y-auto pb-20 pt-10">
        <FlexCol className="px-1 md:px-0 w-full md:w-4/5 lg:w-2/3 mx-auto">
          {sections
            .filter((item) => item.simple || !simpleSearch.value)
            .filter((item) => condoSectionFilter(item, searchSection))
            .filter((item) => rentalSectionFilter(item, searchSection))
            .map((section, index) => {
              return <SectionWrapper title={section.title} key={index} data={section.data} advanced={simpleSearch && !simpleSearch.value} />;
            })}
        </FlexCol>
      </div>
      <div className="flex justify-center items-center px-4 py-4 border-t border-gray-200 shadow-custom1">
        <Button label="RESET FILTERS" labelStyle="py-1 text-primary-red text-base" onClick={() => setShowResetConfirm(true)} />
        <div className="flex flex-row flex-1 justify-end">
          <Link to={`/${publicId}/listing/new/`}>
            <Button label="CANCEL" labelStyle="text-primary2 text-base" onClick={onCancel} />
          </Link>
          <Button
            label="APPLY"
            className="ml-4 bg-primary2 px-5 py-0.5"
            labelStyle="text-base text-white"
            onClick={() => dispatch(submitSearchFormAction())}
          />
        </div>
      </div>
      <ConfirmModal
        visible={showResetConfirm}
        title="Confirm Reset"
        description="Are you sure you want to RESET ALL search values?"
        onClose={() => setShowResetConfirm(false)}
        onConfirm={onConfirmReset}
      />
      {loading && <LoadingView />}
    </div>
  );
};
