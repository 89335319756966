import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleSearchOptionChangeAction } from '_app/store/property/action';
import { simpleSearchFormSelector } from '_app/store/property/selector';

import { FlexCol } from '../FlexCol';
import { FlexRow } from '../FlexRow';
import { Label } from '../Label';

export const SearchModeSelector = () => {
  const dispatch = useDispatch();
  const item = useSelector(simpleSearchFormSelector);
  if (!item) {
    return null;
  }
  const handleChange = (name, value) => {
    dispatch(handleSearchOptionChangeAction({ name, value }));
  };
  return (
    <FlexRow className="rounded overflow-hidden border border-primary4 cursor-pointer">
      <FlexCol className={`px-5 w-24 items-center ${item.value ? 'bg-primary2 text-white' : ''}`} onClick={() => handleChange(item.name, true)}>
        <Label label="Simple" className="text-base" />
      </FlexCol>
      <FlexCol className={`px-5 w-24 items-center ${!item.value ? 'bg-primary2 text-white' : ''}`} onClick={() => handleChange(item.name, false)}>
        <Label label="Advanced" className="text-base" />
      </FlexCol>
    </FlexRow>
  );
};
