import React from 'react';
import { useDispatch } from 'react-redux';
import { handleSearchOptionChangeAction } from '_app/store/property/action';
import { CheckBox, Label } from '..';
import { FlexRow } from '../FlexRow';
import { Input } from '../Input';
import { NullBooleanSelector } from './NullBooleanSelector';

export const PropertyInfoRow = ({ section, className = '', hideLabel = false }) => {
  const dispatch = useDispatch();
  const hasNullOption = section.items.find((item) => item.name.includes('_exclude_null'));
  const onChangeValue = (item, value) => {
    dispatch(handleSearchOptionChangeAction({ ...item, value }));
  };
  return (
    <FlexRow className={className + ' mb-1'}>
      {!hideLabel && (
        <FlexRow className="w-1/3 mr-4 items-center">
          <Label label={section.label + ':'} className="text-right w-full font-primary-bold" />
        </FlexRow>
      )}
      {section.items.map((item, index) => {
        const isInput = !(item.type === 'nullboolean' || item.type === 'boolean');
        if (isInput) {
          return (
            <FlexRow key={index} className={`mr-2 ${hideLabel ? 'w-1/2' : 'w-1/5'}`}>
              <Input
                className="border py-1 border-gray-400"
                containerClassName=""
                type={item.type === 'integer' ? 'number' : 'text'}
                value={item.value || ''}
                onChange={(value) => onChangeValue(item, value)}
              />
            </FlexRow>
          );
        }
        if (item.type === 'nullboolean') {
          return <NullBooleanSelector key={index} item={item} />;
        }
      })}
      <FlexRow className="w-1/6">
        {hasNullOption && (
          <CheckBox
            item={hasNullOption}
            checked={hasNullOption.value}
            labelClassName="text-xl mr-1"
            label="⊘"
            onClick={(value) => onChangeValue(hasNullOption, value)}
          />
        )}
      </FlexRow>
    </FlexRow>
  );
};
