import axios from 'axios';
import { Env } from '_app/config';
import { applyMockService } from './mockApi';

const APIService = axios.create({
  baseURL: Env.API_URL,
  withCredentials: true,
});

// APIService.interceptors.request.use((request) => {
//   request.headers['X-Public-Id'] = Env.PUBLIC_ID;
//   return request;
// });

applyMockService(APIService);

export default APIService;
