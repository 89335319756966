import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Env } from '_app/config';

export const PropertyDetailStreetView = ({ item }) => {
  const apiIsLoaded = (map, maps, ref) => {
    if (map) {
      const panorama = new maps.StreetViewPanorama(ref, {
        position: { lat: item.latitude, lng: item.longitude },
      });
      map.setStreetView(panorama);
    }
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: Env.GOOGLE_API_KEY }}
      center={{ lat: item.latitude, lng: item.longitude }}
      zoom={18}
      onGoogleApiLoaded={({ map, maps, ref }) => apiIsLoaded(map, maps, ref)}
      options={() => ({
        streetViewControl: true,
      })}
    />
  );
};
