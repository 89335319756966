import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CommaInput, OrNotSelector } from '.';
import { FlexCol, Label } from '..';
import { FlexRow } from '../FlexRow';
import { MultiListSelector } from './MultiLIstSelector';

export const LocationInfoRow = ({ section, className = '' }) => {
  return (
    <FlexRow className={className + ' my-2 items-start'}>
      <FlexRow className="w-1/3 mr-4 items-center">
        {section.labelLink && (
          <a href={section.labelLink} target="_blank" rel="noreferrer" className="text-right w-full font-primary-bold text-primary5">
            {section.label + ' '}
            <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="text-primary5" size="sm" />
            {' :'}
          </a>
        )}
        {!section.labelLink && <Label label={section.label + ':'} className="text-right w-full font-primary-bold" />}
      </FlexRow>
      <FlexCol className="w-3/5">
        {section.items.map((item, index) => {
          if (
            item.name === 'search_area' ||
            item.name === 'search_zip_code' ||
            item.name === 'search_subdivision' ||
            item.name === 'search_legal_subdivision' ||
            item.name === 'search_street_name' ||
            item.name === 'search_map_page'
          ) {
            return <CommaInput key={index} item={item} placeholder="Separate #'s by commas." />;
          } else if (item.name === 'search_geo_market_area' || item.name === 'search_city') {
            return <MultiListSelector key={index} item={item} />;
          } else if (item.name.includes('_condition')) {
            return (
              <FlexRow key={index} className="w-full justify-end">
                <OrNotSelector key={index} item={item} />
              </FlexRow>
            );
          }
        })}
      </FlexCol>
    </FlexRow>
  );
};
