import React from 'react';
import { useDispatch } from 'react-redux';
import { handleSearchOptionChangeAction } from '_app/store/property/action';
import { TextArea } from '..';

import { FlexCol } from '../FlexCol';

export const CommaInput = ({ item, placeholder = 'Separate by commas.', className = '' }) => {
  const dispatch = useDispatch();
  const onChange = (value) => {
    dispatch(handleSearchOptionChangeAction({ ...item, value }));
  };
  return (
    <FlexCol className={'items-center lg:flex-row w-full flex-1 ' + className}>
      <TextArea
        className="flex-1 w-full"
        type={item.type}
        placeholder={placeholder}
        value={item.value}
        containerClassName="w-full"
        onChange={(value) => onChange(value)}
      />
    </FlexCol>
  );
};
