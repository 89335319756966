import { handleActions } from 'redux-actions';
import produce from 'immer';

import { FAIL, START, SUCCESS } from '../common';
import { FETCH_ACTIVITY, FETCH_UPCOMINGS } from './action';

const initialState = {
  global: {
    loading: false,
    success: false,
    items: [],
    error: null,
  },
  selected: {
    loading: false,
    success: false,
    items: [],
    error: null,
  },
  globalUpcomings: {
    loading: false,
    success: false,
    items: [],
    error: null,
  },
  selectedUpcomings: {
    loading: false,
    success: false,
    items: [],
    error: null,
  },
};

export default handleActions(
  {
    [FETCH_ACTIVITY + START]: (state, { payload }) =>
      produce(state, (draft) => {
        const key = payload ? 'selected' : 'global';
        draft[key].loading = true;
        draft[key].success = false;
        draft[key].error = null;
      }),
    [FETCH_ACTIVITY + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const key = payload.mlsnum ? 'selected' : 'global';
        draft[key].loading = false;
        draft[key].success = true;
        draft[key].error = null;
        draft[key].items = payload.data;
      }),
    [FETCH_ACTIVITY + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        const key = payload.mlsnum ? 'selected' : 'global';
        draft[key].loading = false;
        draft[key].success = false;
        draft[key].error = payload.data;
      }),
    [FETCH_UPCOMINGS + START]: (state, { payload }) =>
      produce(state, (draft) => {
        const key = payload ? 'selectedUpcomings' : 'globalUpcomings';
        draft[key].loading = true;
        draft[key].success = false;
        draft[key].error = null;
      }),
    [FETCH_UPCOMINGS + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        const key = payload.mlsnum ? 'selectedUpcomings' : 'globalUpcomings';
        draft[key].loading = false;
        draft[key].success = true;
        draft[key].error = null;
        draft[key].items = payload.data;
      }),
    [FETCH_UPCOMINGS + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        const key = payload.mlsnum ? 'selectedUpcomings' : 'globalUpcomings';
        draft[key].loading = false;
        draft[key].success = false;
        draft[key].error = payload.data;
      }),
  },
  initialState,
);
