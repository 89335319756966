import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../Button';
import { FlexCol } from '../FlexCol';
import { FlexRow } from '../FlexRow';
import { HR } from '../HR';
import { ImageSlider } from '../ImageSlider';
import { Label } from '../Label';
import { LoadingView } from '../LoadingView';
import { SchoolTable } from '../SchoolTable';
import { BaseModal } from './BaseModal';
import { FullScreenImageModal } from './FullScreenImageModal';
import { ReactComponent as LIKE_ICON } from '_app/assets/images/like.svg';
import { ReactComponent as DISLIKE_ICON } from '_app/assets/images/dislike.svg';

import Utils from '_app/utils';

import { getSelectedPropertySelector } from '_app/store/property/selector';
import { Link } from 'react-router-dom';
import { ChatPannel, NotificationComponent, PropertyInfoActions } from '..';
import { likePropertyAction } from '_app/store/property/action';
import { fetchChatAction, toggleChatAction } from '_app/store/chat/action';
import { hasNewPrice, isManuallyAdded, isNewProperty } from '_app/utils/propertyParser';
import { publicIdSelector, userSelector } from '_app/store/user/selector';

export const PropertyInfoModal = ({ visible, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const { loading, item, error } = useSelector(getSelectedPropertySelector);
  const user = useSelector(userSelector);
  const [showFullImage, setShowFullImage] = useState(false);
  const publicId = useSelector(publicIdSelector);

  const isStaff = user && !!user.staff_session;

  useEffect(() => {
    if (visible) {
      dispatch(toggleChatAction('close'));
      dispatch(fetchChatAction(item ? item.mlsnum : null));
    } else {
      dispatch(toggleChatAction('close'));
      dispatch(fetchChatAction());
    }
  }, [visible, dispatch, item]);

  useEffect(() => {
    if (error) {
      onClose();
    }
  }, [error]);

  const onLike = (item) => {
    const notification = (
      <NotificationComponent
        text={`${item.address} added to Favs. `}
        undoAction={() => {
          dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating, notification }));
        }}
      />
    );
    dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating === 1 ? 0 : 1, notification }));
  };

  const onDisLike = (item) => {
    const notification = (
      <NotificationComponent
        text={`${item.address} added to Reject. `}
        undoAction={() => {
          dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating, notification }));
        }}
      />
    );
    dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating === -1 ? 0 : -1, notification }));
  };

  return (
    <BaseModal visible={visible} onClose={onClose}>
      {!!item && (
        <FlexCol className="bg-white max-w-screen-sm w-full h-full pb-20 m-auto relative overflow-hidden overflow-y-scroll">
          <div className="w-full h-96 relative">
            <ImageSlider
              images={item.photos}
              imageContainer="w-full h-full"
              imageClassName="w-full h-96"
              enableDragging={false}
              onClick={() => setShowFullImage(true)}
            />
            <div className="flex flex-col z-20 absolute bottom-4 right-4">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onLike(item);
                }}
                className={`rounded-full cursor-pointer flex justify-center items-center w-10 h-10 text-white transition duration-500 ease-in-out hover:bg-primary-red hover:bg-opacity-80 ${
                  item.rating === 1 ? 'bg-primary-red bg-opacity-70' : 'bg-black bg-opacity-40'
                }`}>
                <LIKE_ICON width={26} height={26} fill="white" />
              </div>
              <div
                className={`rounded-full cursor-pointer flex justify-center items-center w-10 h-10 mt-2  hover:bg-primary1 text-white ${
                  item.rating === -1 ? 'bg-primary1' : 'bg-black bg-opacity-40'
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  onDisLike(item);
                }}>
                <DISLIKE_ICON width={20} className="mt-1" fill="white" />
              </div>
            </div>
            <FlexRow className="absolute top-12 left-2 sm:top-4 sm:left-4 items-center z-20">
              {isNewProperty(item) && (
                <Label
                  className="bg-yellow-600 shadow-inner text-white rounded-full mx-1 px-6 pt-0.5 pb-0 font-primary-bold text-sm sm:text-base"
                  label="New"
                />
              )}
              {hasNewPrice(item) && (
                <Label
                  className=" bg-yellow-400 shadow-inner text-white rounded-full mx-1 px-6 pt-0.5 pb-0 font-primary-bold text-sm sm:text-base"
                  label="New Price"
                />
              )}
              {isManuallyAdded(item) && (
                <Label
                  className="bg-purple-600 shadow-inner text-white rounded-full mx-1 px-6 pt-0.5 pb-0 font-primary-bold text-sm sm:text-base"
                  label="Added"
                />
              )}
            </FlexRow>
          </div>
          <FlexRow className="px-3 my-4 justify-around">
            <PropertyInfoActions mlsnum={item.mlsnum} />
          </FlexRow>
          <HR className="border-b" />
          <FlexRow className="px-3 mt-5">
            <Link
              className="flex-1 font-primary-bold text-primary2 text-xl leading-5"
              to={`/${publicId}/details/${item.mlsnum}/`}
              rel="noreferrer"
              target="_blank">
              <div>{item.address}</div>
              <div>
                {item.city}, {item.zip}
              </div>
            </Link>
            <div>
              <Link to={`/${publicId}/details/${item.mlsnum}/`} rel="noreferrer" target="_blank">
                <Button label="Full details" className="border-black border-2 px-2" labelStyle="font-primary-bold text-base" />
              </Link>
            </div>
          </FlexRow>
          <HR className="mt-2" />
          <FlexCol className="px-3 py-2">
            <Label className="font-primary-bold text-primary2 text-xl" label={`$${(item.price || 0).toLocaleString()}`} />
            <FlexRow>
              <FlexCol className="flex-1">
                {!!item.bedrooms && (
                  <FlexRow>
                    <Label label={item.bedrooms} className="mr-2 font-primary-bold text-base leading-5" />
                    <Label label={`Bed${item.bedrooms > 1 ? 's' : ''}`} className="font-primary text-sm pt-px" />
                  </FlexRow>
                )}
                {!!item.full_bathrooms && (
                  <FlexRow>
                    <Label label={item.full_bathrooms} className="mr-2 font-primary-bold text-base leading-5" />
                    <Label label={`Full bath${item.full_bathrooms > 1 ? 's' : ''}`} className="font-primary text-sm pt-px" />
                  </FlexRow>
                )}
                {!!item.half_bathrooms && (
                  <FlexRow>
                    <Label label={item.half_bathrooms} className="mr-2 font-primary-bold text-base leading-5" />
                    <Label label={`Half bath${item.half_bathrooms > 1 ? 's' : ''}`} className="font-primary text-sm pt-px" />
                  </FlexRow>
                )}
              </FlexCol>
              <FlexCol className="flex-1">
                {!!item.building_size && (
                  <FlexRow>
                    <Label label={item.building_size.toLocaleString()} className="mr-2 font-primary-bold text-base leading-5" />
                    <Label label="Building Sqft." className="font-primary text-sm pt-px" />
                  </FlexRow>
                )}
                {!!item.lot_size && (
                  <FlexRow>
                    <Label label={item.lot_size.toLocaleString()} className="mr-2 font-primary-bold text-base leading-5" />
                    <Label label="Lot Sqft." className="font-primary text-sm pt-px" />
                  </FlexRow>
                )}
                <FlexRow>
                  <Label label={item.property_type} className="mr-2 font-primary text-sm pt-px leading-5" />
                </FlexRow>
              </FlexCol>
            </FlexRow>
          </FlexCol>
          <div className="px-3">
            <HR className="my-6" />
            <Label label="General Description" className="font-primary-bold text-primary2 text-xl" />
            <Label label={`${isStaff ? `${item.mlsnum} ` : ''} ${item.public_description}`} className="text-base" />
          </div>
          {Utils.generatePropertyGeneralRows(item).map(({ value, label }, index) => (
            <div key={index} className="px-3">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="px-3 mt-12">
            <Label label="Taxes & Fees" className="font-primary-bold text-xl text-primary2" />
          </div>
          {Utils.generatePropertyTaxRows(item).map(({ value, label, isLink }, index) => (
            <div key={index} className="px-3">
              {!isLink && <HR className="my-3" />}
              <FlexRow className={isLink ? 'justify-center mt-4' : ''}>
                {isLink && (
                  <a href={value} rel="noreferrer" target="_blank">
                    <Label label={label} className="font-primary-bold text-base flex-1 text-primary5" />
                  </a>
                )}
                {!isLink && (
                  <>
                    <Label label={label} className="font-primary-bold text-base flex-1" />
                    <Label label={value} className="text-base flex-1" />
                  </>
                )}
              </FlexRow>
            </div>
          ))}
          <div className="px-3 mt-12">
            <Label label="Room/Lot Size" className="font-primary-bold text-xl text-primary2" />
          </div>
          {Utils.generatePropertyRoomRows(item).map(({ value, label }, index) => (
            <div key={index} className="px-3">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="px-3 mt-12">
            <Label label="Interior Features" className="font-primary-bold text-xl text-primary2" />
          </div>
          {Utils.generatePropertyInteriorRows(item).map(({ value, label }, index) => (
            <div key={index} className="px-3">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="px-3 mt-12">
            <Label label="Exterior Features" className="font-primary-bold text-xl text-primary2" />
          </div>
          {Utils.generatePropertyExteriorRows(item).map(({ value, label }, index) => (
            <div key={index} className="px-3">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="px-3">
            <SchoolTable property={item} />
          </div>
        </FlexCol>
      )}
      {loading && <LoadingView />}
      <button className="top-2 left-2 fixed bg-gray-200 text-black px-6 py-1 rounded font-primary-bold text-base" onClick={onClose}>
        back
      </button>
      {showFullImage && <FullScreenImageModal visible={showFullImage} slideIndex={0} images={item.photos} onClose={() => setShowFullImage(false)} />}
      <ChatPannel />
    </BaseModal>
  );
};
