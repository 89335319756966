import React from 'react';
import { useDispatch } from 'react-redux';
import { handleSearchOptionChangeAction } from '_app/store/property/action';
import { RadioBox } from '..';

import { FlexRow } from '../FlexRow';

export const NullBooleanSelector = ({ item, className = '' }) => {
  const dispatch = useDispatch();
  const onChangeValue = (value) => {
    dispatch(handleSearchOptionChangeAction({ ...item, value }));
  };
  return (
    <FlexRow className={className}>
      <RadioBox checked={item.value === 2} labelClassName="mr-0" className="mr-4" label="N/A" onClick={() => onChangeValue(2)} />
      <RadioBox checked={item.value === 1} labelClassName="mr-0" className="mr-4" label="Yes" onClick={() => onChangeValue(1)} />
      <RadioBox checked={item.value === 0} labelClassName="mr-0" className="mr-4" label="No" onClick={() => onChangeValue(0)} />
    </FlexRow>
  );
};
