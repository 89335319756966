import React from 'react';

export const Button = ({ label = 'Button', labelStyle = '', className = '', onClick = () => {}, leftIcon }) => {
  return (
    <button
      className={`text-sm items-center font-primary-bold rounded cursor-pointer flex flex-row hover:bg-opacity-80 ${className}`}
      onClick={onClick}>
      {leftIcon && <div className="pr-1">{leftIcon}</div>}
      <span className={`mt-0.5 ${labelStyle}`}>{label}</span>
    </button>
  );
};
