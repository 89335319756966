import React from 'react';

import { CommaInput, OrNotSelector } from '.';
import { FlexCol, Label } from '..';
import { FlexRow } from '../FlexRow';
import { MultiListSelector } from './MultiLIstSelector';
import { NullBooleanSelector } from './NullBooleanSelector';
import { PropertyInfoRow } from './PropertyInfoRow';

export const AdditionalInfoRow = ({ section, className = '' }) => {
  return (
    <FlexCol className={className + ' my-2 mx-4 items-center'}>
      <FlexRow className="items-start w-full mb-2">
        <Label label={section.label + ':'} className="text-left font-primary-bold" />
      </FlexRow>
      {section.type === 'rangeInput' && <PropertyInfoRow className="w-full" section={section} hideLabel />}
      {section.type !== 'rangeInput' && (
        <FlexCol className="w-full">
          {section.items.map((item, index) => {
            if (section.type === 'input') {
              if (!item.name.includes('_condition')) {
                return <CommaInput key={index} item={item} />;
              } else if (item.name.includes('_condition')) {
                return (
                  <FlexRow key={index} className="w-full justify-end">
                    <OrNotSelector key={index} item={item} />
                  </FlexRow>
                );
              }
            } else if (item.name.includes('_condition') || item.name.includes('_yn')) {
              if (item.name.includes('_yn')) {
                return <NullBooleanSelector key={index} item={item} className="w-1/2" />;
              }
              return (
                <FlexRow key={index} className="w-full justify-end">
                  <OrNotSelector key={index} item={item} />
                </FlexRow>
              );
            } else if (!item.name.includes('_condition')) {
              return <MultiListSelector key={index} item={item} />;
            }
          })}
        </FlexCol>
      )}
    </FlexCol>
  );
};
