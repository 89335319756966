import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const rootReducerInstance = rootReducer(history);

const configureStore = async () => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
  };

  const sagaMiddleware = createSagaMiddleware();

  const persistedReducer = persistReducer(persistConfig, rootReducerInstance);

  const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware, routerMiddleware(history)), sentryReduxEnhancer));

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
