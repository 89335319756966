import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MapFilterTabs } from '_app/constants/types';
import { LoadingView } from './LoadingView';

import { getPropertySelector } from '_app/store/property/selector';
import { fetchPropertyAction, preSelectPropertyAction } from '_app/store/property/action';
import { Link } from 'react-router-dom';
import { PropertyList } from './PropertyList';
import { publicIdSelector } from '_app/store/user/selector';

export const PropertyPanel = ({ onClickItem = () => {} }) => {
  const dispatch = useDispatch();
  const publicId = useSelector(publicIdSelector);
  const { preSelected, filter, items = [], loading, loadingMore, mapReady, toggleList, tabCounts, success } = useSelector(getPropertySelector);

  useEffect(() => {
    if (mapReady) {
      dispatch(fetchPropertyAction());
    }
  }, [dispatch, mapReady, filter.tab]);

  const onPreSelectItem = (item) => {
    dispatch(preSelectPropertyAction(item));
  };

  return (
    <div className="h-full flex flex-col w-full">
      <div className="flex flex-row justify-around bg-primary2 shadow">
        {Object.keys(MapFilterTabs).map((tab, index) => (
          <Link
            key={index}
            to={`/${publicId}/listing/${MapFilterTabs[tab].path}/`}
            className={`flex items-center justify-center flex-1 py-2 font-semibold text-sm xl:text-sm text-center border-primary2 bg-white hover:text-primary1 ${
              MapFilterTabs[tab].label === filter.tab.label ? 'border-primary2 border-b-4 text-primary2' : 'text-primary3'
            }`}>
            <span>{MapFilterTabs[tab].label}</span>
            {!!tabCounts[MapFilterTabs[tab].tabId] && <span className="text-xs ml-1">{`(${tabCounts[MapFilterTabs[tab].tabId]})`}</span>}
          </Link>
        ))}
      </div>
      <div className="relative flex-1">
        <PropertyList onClickItem={onClickItem} preSelected={preSelected} toggleList={toggleList} items={items} onPreSelectItem={onPreSelectItem} />
        {items.length === 0 && success && (
          <div className="absolute inset-0 flex justify-center items-center bg-gray-200">
            <div>No properties found.</div>
          </div>
        )}
        {items.length > 0 && !loading && loadingMore && (
          <div className="absolute bottom-0 left-0 right-0 h-40 flex justify-center items-center z-50">
            <div className="bg-white bg-opacity-80 shadow-xl px-4 text-sm pt-0.5 font-primary-bold text-primary2 rounded-full">Loading more...</div>
          </div>
        )}
        {(!mapReady || loading) && (
          <LoadingView size={120} stroke={4} color="white" label="Loading Properties..." labelStyle="text-white font-primary-bold text-xl" />
        )}
      </div>
    </div>
  );
};
