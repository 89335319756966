import React from 'react';

export const ErrorReport = ({ error, resetError }) => {
  return (
    <div className="flex flex-1 justify-center items-center flex-col h-full px-4">
      <p className="text-2xl">Oops, sorry</p>
      <p>{"There's and error."}</p>
      <a href="https://www.houstonproperties.com/" className="cursor-pointer text-primary4" onClick={resetError}>
        Go to Site
      </a>
      <br />
      <div className="text-red-500 max-h-96 overflow-y-auto">
        <p>{error.message}</p>
        <p>{error.stack}</p>
      </div>
    </div>
  );
};
