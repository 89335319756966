import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export const BaseModal = ({ children, visible, className = '', overlayClassName = '', onClose = () => {} }) => {
  return (
    <Modal
      isOpen={visible}
      overlayClassName={`absolute inset-0 z-top bg-black bg-opacity-80 flex justify-center overflow-hidden ` + overlayClassName}
      className={'outline-none overflow-hidden ' + className}
      onRequestClose={onClose}>
      {children}
    </Modal>
  );
};
