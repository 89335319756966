import React from 'react';

import { FlexCol } from '../FlexCol';
import { RentalsInfoRow } from './RentalsInfoRow';

export const RentalsInfoSectionForm = ({ data = {}, advanced = false }) => {
  const list = data.filter((item) => (advanced ? item : !item.advanced));
  return (
    <FlexCol className="w-full lg:flex-row">
      {list.map((section, index) => {
        return (
          <FlexCol key={index} className="w-full">
            <RentalsInfoRow section={section} />
          </FlexCol>
        );
      })}
    </FlexCol>
  );
};
