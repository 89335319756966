import APIService from '_app/services/api';
import { all, fork, put, take, select, takeLatest, delay } from 'redux-saga/effects';
import { createMatchSelector, LOCATION_CHANGE, push } from 'connected-react-router';

import { FAIL, START, SUCCESS } from '../common';
import { FETCH_PROFILE, SET_PREVIOUS_ROUTE, SET_PUBLIC_ID, SYNC_SERVER_STATUS, TOGGLE_NAVBAR } from './action';
import Utils from '_app/utils';
import ROUTES from '_app/pages/routes';

function* fetchProfile() {
  while (true) {
    yield take(FETCH_PROFILE + START);
    try {
      const { data } = yield APIService.get('/profile/');
      if (data.redirect_url) {
        window.location = data.redirect_url;
      } else {
        yield put({ type: FETCH_PROFILE + SUCCESS, payload: data });
      }
      yield put({ type: SET_PREVIOUS_ROUTE, payload: null });
    } catch (error) {
      const errorObj = Utils.getError(error);
      yield put({ type: FETCH_PROFILE + FAIL, payload: errorObj });
      if (errorObj.status === 503) {
        yield put({ type: SET_PREVIOUS_ROUTE, payload: window.location.href });
        yield put(push('/maintenance'));
        yield put({ type: SYNC_SERVER_STATUS });
      } else {
        yield put({ type: SET_PREVIOUS_ROUTE, payload: null });
        Utils.showErrorToast(Utils.getError(error).message);
      }
    }
  }
}

function* setPublicId() {
  const state = yield select();
  const match = ROUTES.filter((route) => route.logoRedirect)
    .map((route) => {
      const matchSelector = createMatchSelector(route);
      return matchSelector(state);
    })
    .find((router) => !!router);
  if (match && match.params && match.params.publicId) {
    yield put({ type: SET_PUBLIC_ID, payload: match.params.publicId });
    APIService.defaults.headers.common['X-Public-Id'] = match.params.publicId;
    yield put({ type: TOGGLE_NAVBAR, payload: !!match.params.propertyId });
  } else {
    yield put({ type: SET_PUBLIC_ID, payload: null });
    yield put({ type: TOGGLE_NAVBAR, payload: true });
  }
}

function* syncServerStatus() {
  yield delay(5000);
  const state = yield select();
  try {
    yield APIService.get('/profile/');
    if (state.user.publicId) {
      window.location = `${window.location.origin}/${state.user.publicId}/listing/new/`;
    } else {
      window.location = window.location.origin;
    }
  } catch (e) {
    const error = Utils.getError(e);
    if (error.status !== 503) {
      if (state.user.publicId) {
        window.location = `${window.location.origin}/${state.user.publicId}/listing/new/`;
      } else {
        window.location = window.location.origin;
      }
    }
    yield put({ type: SYNC_SERVER_STATUS });
  }
}

export default function* sagas() {
  yield all([
    fork(fetchProfile),
    takeLatest([LOCATION_CHANGE, FETCH_PROFILE + SUCCESS], setPublicId),
    takeLatest(SYNC_SERVER_STATUS, syncServerStatus),
  ]);
}
