// check arr includes all target array elements
export const isIncludingAll = (arr, target) => target.every((v) => arr.includes(v));

export const isIncludingSome = (arr, target) => target.some((v) => arr.includes(v));

export const formatPhoneInput = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) {
      return currentValue;
    }
    if (cvLength < 7) {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    }
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};
