import { toast } from 'react-toastify';

export const showErrorToast = (message) => {
  toast.error(message, { hideProgressBar: true, autoClose: 3000 });
};

export const showInfoToast = (message) => {
  toast.info(message, { hideProgressBar: true, autoClose: 5000 });
};

export const showSuccessToast = (message) => {
  toast.success(message, { hideProgressBar: true, autoClose: 2000 });
};
