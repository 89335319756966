import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from './Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from 'react-redux';
import { showNavBarSelector, userSelector } from '_app/store/user/selector';

const menu_items = [
  { label: 'Neighborhoods', href: 'https://www.houstonproperties.com/houston-neighborhoods?np=1', target: '_blank' },
  { label: 'Value My Current Home', href: 'https://my.houstonproperties.com/home-valuation/55156FEv/activity/', target: '_blank' },
];

export const NavBar = ({ onToggleMenu = () => {} }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isStaff = user && !!user.staff_session;

  const showNavBar = useSelector(showNavBarSelector);
  if (!showNavBar) {
    return null;
  }
  return (
    <div className="flex flex-row justify-between px-4 w-full bg-white border-b items-center shadow">
      <div className="py-2">
        <Logo />
      </div>
      <div className="sm:hidden p-2 cursor-pointer" onClick={onToggleMenu}>
        <FontAwesomeIcon icon={['fas', 'bars']} className="text-primary1 text-xl cursor-pointer" />
      </div>
      <ul className="flex-1 flex-row justify-end items-center hidden sm:flex">
        {menu_items.map(({ label, href, action, target }) => (
          <li
            className="px-2 py-4 hover:bg-primary2 hover:text-white cursor-pointer font-primary-bold text-primary2"
            key={label}
            onClick={() => action && dispatch(action())}>
            <Link to={{ pathname: href }} target={target}>
              {label === 'owner' ? (user.lead_owner ? `Ask ${user.lead_owner.name}` : '') : label}
            </Link>
          </li>
        ))}
        {isStaff && !!user.profile_url && (
          <li className="px-2 py-4 hover:bg-primary2 hover:text-white cursor-pointer font-primary-bold text-yellow-600">
            <a href={user.profile_url} rel="noreferrer noopener" target="_blank">
              Edit {user.name} {user.surname} <FontAwesomeIcon icon={['fas', 'edit']} className="text-base text-yellow-600 mr-2" />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
