import React from 'react';

import { ReactComponent as LIKE_ICON } from '_app/assets/images/like.svg';
import { ReactComponent as DISLIKE_ICON } from '_app/assets/images/dislike.svg';
import { FlexRow } from '.';
import { generateShortPropertyValueRow, hasNewPrice, isManuallyAdded, isNewProperty } from '_app/utils/propertyParser';

export const PropertyListItem = ({ item, onClick = () => {}, onMouseEnter = () => {}, onLike = () => {}, onDisLike = () => {} }) => {
  return (
    <div className="h-full w-full" onClick={onClick} onMouseEnter={onMouseEnter}>
      <div className="w-full overflow-hidden aspect-w-4 aspect-h-3 relative">
        <img src={item.photos[0].url || '/images/logo.png'} alt="Property Image" className="object-cover h-full" />
        <div className="w-full h-full">
          <div className="absolute left-0 right-0 bottom-0 pt-4 px-1 bg-gradient-to-t from-gray-900">
            <div className="text-white font-primary-bold text-lg leading-5">${item.price.toLocaleString()}</div>
            <div className="text-white font-primary text-sm">{item.property_type}</div>
          </div>
          <FlexRow className="absolute top-1 left-0.5 items-center">
            {isNewProperty(item) && (
              <div className="bg-yellow-600 shadow-inner text-white rounded-full mx-0.5 px-2 pt-0.5 pb-0 font-primary-bold text-xs">New</div>
            )}
            {hasNewPrice(item) && (
              <div className=" bg-yellow-400 shadow-inner text-white rounded-full mx-0.5 px-2 pt-0.5 pb-0 font-primary-bold text-xs">New Price</div>
            )}
            {isManuallyAdded(item) && (
              <div className="bg-purple-600 shadow-inner text-white rounded-full mx-0.5 px-2 pt-0.5 pb-0 font-primary-bold text-xs">Added</div>
            )}
          </FlexRow>
        </div>
        <div className="w-full justify-start items-end flex flex-col mt-0.5 pr-1 z-40">
          <div
            onClick={(e) => {
              e.stopPropagation();
              onLike(item);
            }}
            className={`rounded-full flex justify-center items-center w-10 h-10 text-white transition duration-500 ease-in-out hover:bg-primary-red hover:bg-opacity-80 ${
              item.rating === 1 ? 'bg-primary-red bg-opacity-70' : 'bg-black bg-opacity-40'
            }`}>
            <LIKE_ICON width={26} height={26} fill="white" />
          </div>
          <div
            className={`rounded-full flex justify-center items-center w-10 h-10 mt-0.5  hover:bg-primary1 text-white ${
              item.rating === -1 ? 'bg-primary1' : 'bg-black bg-opacity-40'
            }`}
            onClick={(e) => {
              e.stopPropagation();
              onDisLike(item);
            }}>
            <DISLIKE_ICON width={20} className="mt-1" fill="white" />
          </div>
        </div>
      </div>
      <div className="px-2">
        <div className="font-primary-bold text-sm mt-2 leading-4">{item.address}</div>
        <div className="font-primary text-sm">{generateShortPropertyValueRow(item)}</div>
      </div>
    </div>
  );
};
