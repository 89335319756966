import React from 'react';
import { useDispatch } from 'react-redux';
import { handleSearchOptionChangeAction } from '_app/store/property/action';
import { CheckBox } from '../CheckBox';
import { FlexCol } from '../FlexCol';

export const BuyRentSelector = ({ item, className = '' }) => {
  const dispatch = useDispatch();
  const { value: selectedValues = [] } = item;
  const onChangeValue = (value) => {
    if (item.value && item.value.includes(value)) {
      dispatch(handleSearchOptionChangeAction({ ...item, value: item.value.filter((sItem) => sItem !== value) }));
    } else {
      dispatch(handleSearchOptionChangeAction({ ...item, value: item.value ? [...item.value, value] : [value] }));
    }
  };
  return (
    <FlexCol className={className}>
      {item.choices.map(({ label, value }, index) => (
        <CheckBox
          key={index}
          label={label}
          checked={selectedValues && selectedValues.includes(value)}
          labelClassName="mr-2 font-primary-bold"
          className="mt-2 mx-4"
          onClick={() => onChangeValue(value)}
        />
      ))}
    </FlexCol>
  );
};
