import React from 'react';
import { useDispatch } from 'react-redux';
import { handleSearchOptionChangeAction } from '_app/store/property/action';

import { FlexRow } from '../FlexRow';
import { RadioBox } from '../RadioBox';

const checkboxStyle = 'p-1';

export const OrNotSelector = ({ item }) => {
  const dispatch = useDispatch();
  const onChangeValue = (value) => {
    if (item.value === value) {
      return;
    }
    dispatch(handleSearchOptionChangeAction({ ...item, value }));
  };
  return (
    <FlexRow className="">
      {item.choices &&
        item.choices.map(({ label, value }, index) => (
          <RadioBox
            key={index}
            label={label}
            checked={value === item.value}
            inputClassName="w-4 h-4"
            className={checkboxStyle + (index === 0 ? ' ml-0' : '') + (index === 2 ? ' mr-0' : '')}
            onClick={() => onChangeValue(value)}
          />
        ))}
    </FlexRow>
  );
};
