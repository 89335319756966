import MapSearch from './MapSearch';
import PropertyDetail from './PropertyDetail';
import NotFound from './NotFound';
import SearchForm from './SearchForm';
import Maintenance from './Maintenance';

const ROUTES = [
  { path: '/', key: 'NotFound', exact: true, component: NotFound },
  { path: `/:publicId/search-form/`, key: 'SearchPanel', exact: true, component: SearchForm, logoRedirect: true },
  { path: `/:publicId/listing/:tab/`, key: 'MapSearch', exact: true, component: MapSearch, logoRedirect: true },
  { path: `/:publicId/details/:propertyId/`, key: 'PropertyDetail', exact: true, component: PropertyDetail, logoRedirect: true },
  { path: '/not-found/', key: 'NotFound', exact: true, component: NotFound },
  { path: '/maintenance/', key: 'Maintenance', exact: true, component: Maintenance },
];

export default ROUTES;
