import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { copyToClipboard } from '_app/utils/copyToClipboard';
import { showInfoToast } from '_app/utils/toast';

export const CopyIcon = ({ text, size = 'lg' }) => {
  const onCopy = () => {
    if (!text || !text.trim()) {
      return;
    }
    copyToClipboard(text);
    showInfoToast(`Copied value to clipboard: "${text}"`);
  };
  return <FontAwesomeIcon icon={['fas', 'copy']} className="text-primary5 cursor-pointer hover:text-primary1" size={size} onClick={onCopy} />;
};
