import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';

import { MobileMenu, NavBar } from '_app/components';
import { MapFilterTabs } from '_app/constants/types';
import { fetchProfileAction } from '_app/store/user/action';
import { publicIdSelector } from '_app/store/user/selector';
import ROUTES from './routes';

const AvailableMapSearchPaths = Object.keys(MapFilterTabs).map((key) => MapFilterTabs[key]);

const Pages = () => {
  const dispatch = useDispatch();
  const publicId = useSelector(publicIdSelector);
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  useEffect(() => {
    dispatch(fetchProfileAction());
  }, [dispatch]);
  return (
    <div className="h-full w-full flex flex-col relative overflow-hidden">
      <NavBar onToggleMenu={() => setToggleMobileMenu(true)} />
      <Switch>
        {ROUTES.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        <Redirect from={`/${publicId}/listing`} to={`/${publicId}/listing/${AvailableMapSearchPaths[0].path}/`} />
        <Redirect to="/not-found" />
      </Switch>
      <MobileMenu visible={toggleMobileMenu} onClose={() => setToggleMobileMenu(false)} />
    </div>
  );
};

export default Pages;
