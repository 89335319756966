import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import { ChatPannel, DrawingGuard, MapBoard, PropertyPanel, PropertySearchAutoComplete, ToolBar } from '_app/components';
import { PropertyInfoModal, RecentActivitiesModal } from '_app/components/modals';
import { MapFilterTabs } from '_app/constants/types';

import { addPropertyAction, fetchPropertyInfoAction, fetchSearchOptionAction, updatePropertyFilterAction } from '_app/store/property/action';
import { fetchChatAction } from '_app/store/chat/action';
import { toggleListSelector, toggleSearchFilterSelector } from '_app/store/property/selector';

const AvailableMapSearchPaths = Object.keys(MapFilterTabs).map((key) => MapFilterTabs[key]);

const MapSearch = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const toggleSearchFilter = useSelector(toggleSearchFilterSelector);
  const toggleList = useSelector(toggleListSelector);

  const [openPreview, setOpenPreview] = useState(false);
  const [openActivity, setOpenActivity] = useState(false);

  useEffect(() => {
    dispatch(fetchSearchOptionAction());
    dispatch(fetchChatAction());
  }, [dispatch]);
  useEffect(() => {
    const linkedTab = AvailableMapSearchPaths.find((item) => item.path === params.tab);
    if (linkedTab) {
      dispatch(updatePropertyFilterAction({ key: 'tab', value: linkedTab }));
    }
  }, [dispatch, params.tab]);

  const onOpenPreview = ({ mlsnum }) => {
    dispatch(fetchPropertyInfoAction(mlsnum));
    setOpenPreview(true);
  };

  return (
    <div className="flex flex-1 flex-col justify-center items-center font-primary relative overflow-hidden">
      <ToolBar visibleSearch={toggleSearchFilter} visibleList={toggleList} toggleActivity={() => setOpenActivity(true)} />
      <div className="flex-1 w-full flex h-full flex-row relative">
        <div className={`flex-col z-30 relative border-r ${!toggleList ? 'w-1/3 hidden md:flex' : 'absolute inset-0 h-full w-full'}`}>
          <PropertySearchAutoComplete onSelectAddress={(item) => dispatch(addPropertyAction(item))} />
          <PropertyPanel onClickItem={onOpenPreview} />
          <DrawingGuard />
        </div>
        <MapBoard toggleList={toggleList} onClickMarker={onOpenPreview} />
      </div>
      {!toggleSearchFilter && !toggleList && !openPreview && <ChatPannel />}
      <PropertyInfoModal visible={openPreview} onClose={() => setOpenPreview(false)} />
      <RecentActivitiesModal visible={openActivity} onClose={() => setOpenActivity(false)} />
    </div>
  );
};

export default MapSearch;
