import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from '_app/assets/images/close.svg';
import { sendChatAction, toggleChatAction } from '_app/store/chat/action';
import { chatSelector } from '_app/store/chat/selector';
import { userSelector } from '_app/store/user/selector';
import { FlexRow, LoadingView } from '.';
import { Button } from './Button';
import { ChatMessageItem } from './ChatMessageItem';

export const ChatPannel = () => {
  const dispatch = useDispatch();
  const { items, loading, chatVisible } = useSelector(chatSelector);
  const { name: userName, lead_owner } = useSelector(userSelector);
  const [value, setValue] = useState('');
  const scrollRef = useRef();

  if (!chatVisible && window.location.hash === '#chat' && !loading && !!lead_owner) {
    dispatch(toggleChatAction('open'));
    history.replaceState(null, null, ' ');
  }

  useEffect(() => {
    if (items.length) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [items]);

  const onSendMessage = () => {
    if (!value.trim()) {
      return;
    }

    const newMessage = { message: value, datetime: Date.now(), sender: { name: userName || 'Anonymous', is_agent: false } };
    dispatch(sendChatAction(newMessage));
    setValue('');
  };

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      onSendMessage();
    }
  };
  return (
    <div
      className={`z-20 transform transition-all ease-out w-full duration-300 shadow-custom2 fixed bottom-0 top-0 font-primary sm:w-96 ${
        chatVisible ? 'right-0' : '-right-full sm:-right-96'
      }`}>
      <div
        className={`absolute bottom-4 -left-20 hover:bg-opacity-70 rounded-full h-16 w-16 flex justify-center items-center bg-primary2 cursor-pointer`}
        onClick={() => dispatch(toggleChatAction(!chatVisible))}>
        {chatVisible && <CloseIcon width={30} height={30} fill="white" />}
        {!chatVisible && (
          <div>
            <FontAwesomeIcon icon={['fas', 'comments']} className="text-2xl text-white" />
            <div className="absolute inset-0 animate-fade bg-primary2 w-full h-full rounded-full flex items-center justify-center">
              {!!lead_owner && <img src={lead_owner && lead_owner.photo} className="w-full h-full rounded-full border border-primary2" />}
            </div>
          </div>
        )}
      </div>
      <div className={`w-full sm:w-96 h-full bg-white flex flex-col`}>
        <FlexRow className="justify-between items-center border-b p-4 pt-5">
          <div className="text-primary-red font-primary-bold">HOW CAN I BEST HELP?</div>
          <div
            className={`flex justify-center items-center cursor-pointer transform transition ease-out duration-200 ${chatVisible ? 'sm:hidden' : ''}`}
            onClick={() => dispatch(toggleChatAction(!chatVisible))}>
            <CloseIcon width={30} height={30} fill="black" />
          </div>
        </FlexRow>
        <div className="flex-1 overflow-y-auto flex flex-col">
          {items.map((item, index) => (
            <ChatMessageItem key={index} item={item} />
          ))}
          <div ref={scrollRef} />
        </div>
        <div className="flex flex-row w-full bg-gray-300 p-1.5">
          <input
            type="text"
            value={value}
            onChange={({ target }) => setValue(target.value)}
            onKeyDown={handleKeydown}
            className="rounded flex-1 text-lg p-1 pl-3 outline-none"
            placeholder="Type a message"
          />
          <Button label="Send" className="bg-primary2 px-4 ml-2" labelStyle="text-white" onClick={onSendMessage} />
        </div>
      </div>
      {loading && <LoadingView />}
    </div>
  );
};
