import { handleActions, combineActions } from 'redux-actions';
import produce from 'immer';
import { isMobile } from 'react-device-detect';

import Constants from '_app/constants';
import { MapFilterTabs } from '_app/constants/types';

import { CANCEL, FAIL, START, SUCCESS } from '../common';
import {
  ASK_PROPERTY_FINANCING,
  ASK_PROPERTY_MORE_INFO,
  ASK_PROPERTY_TOUR,
  CANCEL_SEARCH_FORM,
  CLEAR_MAP,
  FETCH_PROPERTY,
  FETCH_PROPERTY_INFO,
  FETCH_SEARCH_OPTION,
  HANDLE_SEARCH_OPTION_CHANGE,
  INIT_ASK_PROPERTY,
  PRESELECT_PROPERTY,
  RESET_PROPERTY_INFO,
  RESET_SEARCH_FORM,
  SET_MAP_DRAW_MODE,
  SET_MAP_READY,
  SET_TAB_COUNTS,
  SUBMIT_SEARCH_FORM,
  TOGGLE_LIST,
  TOGGLE_SEARCH_FILTER,
  UPDATE_MAP_CENTER,
  UPDATE_MAP_ZOOM,
  UPDATE_PROPERTY_FILTER,
} from './action';
import { mapSearchFormBySection } from '_app/utils/mapper';

const initialState = {
  filter: {
    tab: MapFilterTabs.NEW,
    bounds: [],
    searchAsMove: true,
  },
  search: {
    originItems: [],
    items: [],
    sections: [],
    loading: false,
    error: null,
    success: false,
  },
  toggleSearchFilter: false,
  toggleList: isMobile,
  mapCenter: { lat: 29.717971369549744, lng: -95.43397342215448 },
  mapZoom: Constants.DefaultMapZoom,
  mapReady: false,
  isMapDrawing: false,
  preSelected: null,
  selected: {
    loading: false,
    success: false,
    error: null,
    item: null,
  },
  loading: false,
  success: false,
  error: null,
  total: 0,
  page: 1,
  hasMore: false,
  loadingMore: false,
  items: [],
  tabCounts: {},
  askProperty: {
    loading: false,
    success: false,
    error: null,
  },
};

export default handleActions(
  {
    [FETCH_PROPERTY + START]: (state, { payload = true }) =>
      produce(state, (draft) => {
        if (payload) {
          draft.loading = true;
          draft.success = false;
          draft.error = null;
          draft.page = 1;
          draft.total = 0;
          draft.hasMore = false;
        } else {
          draft.loadingMore = true;
        }
      }),
    [FETCH_PROPERTY + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.error = null;
        draft.total = payload.total_properties;
        draft.page = payload.page || 1;
        draft.items = draft.page === 1 ? payload.properties : [...state.items, ...payload.properties];
        draft.hasMore = draft.total > draft.items.length;
        draft.loadingMore = false;
      }),
    [FETCH_PROPERTY + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
        draft.loadingMore = false;
      }),
    [FETCH_PROPERTY + CANCEL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
        draft.loadingMore = false;
      }),
    [FETCH_PROPERTY_INFO + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected.loading = true;
        draft.selected.success = false;
        draft.selected.error = null;
        if (state.selected.item && state.selected.item.mlsnum !== payload) {
          draft.selected.item = null;
        }
      }),
    [FETCH_PROPERTY_INFO + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected.loading = false;
        draft.selected.success = true;
        draft.selected.error = null;
        draft.selected.item = payload;
      }),
    [RESET_PROPERTY_INFO]: (state) =>
      produce(state, (draft) => {
        draft.selected = initialState.selected;
      }),
    [FETCH_PROPERTY_INFO + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected.loading = false;
        draft.selected.success = false;
        draft.selected.error = payload;
      }),
    [PRESELECT_PROPERTY]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.preSelected = payload;
      }),
    [UPDATE_PROPERTY_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.filter[payload.key] = payload.value;
        draft.preSelected = null;
      }),
    [UPDATE_MAP_CENTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.mapCenter = payload.location;
        if (payload.zoom) {
          draft.mapZoom = payload.zoom;
        }
      }),
    [UPDATE_MAP_ZOOM]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.mapZoom = payload;
      }),
    [SET_MAP_DRAW_MODE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isMapDrawing = payload;
      }),
    [SET_MAP_READY]: (state) =>
      produce(state, (draft) => {
        draft.mapReady = true;
      }),
    [CLEAR_MAP]: (state) =>
      produce(state, (draft) => {
        draft.items = [];
        draft.preSelected = null;
        draft.filter.bounds = [];
        draft.mapCenter = initialState.mapCenter;
        draft.mapZoom = initialState.mapZoom;
      }),
    [TOGGLE_SEARCH_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.toggleSearchFilter = payload !== undefined ? payload : !state.toggleSearchFilter;
      }),
    [TOGGLE_LIST]: (state) =>
      produce(state, (draft) => {
        draft.toggleList = !state.toggleList;
      }),
    [FETCH_SEARCH_OPTION + START]: (state) =>
      produce(state, (draft) => {
        draft.search.loading = true;
        draft.search.success = false;
        draft.search.error = null;
      }),
    [FETCH_SEARCH_OPTION + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.search.loading = false;
        draft.search.success = true;
        draft.search.originItems = payload;
        draft.search.items = payload;
        draft.search.sections = mapSearchFormBySection(payload);
        draft.search.error = null;
      }),
    [FETCH_SEARCH_OPTION + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.search.loading = false;
        draft.search.success = false;
        draft.search.error = payload;
      }),
    [HANDLE_SEARCH_OPTION_CHANGE]: (state, { payload }) =>
      produce(state, (draft) => {
        const index = state.search.items.findIndex((item) => item.name === payload.name);
        if (index > -1) {
          draft.search.items[index] = payload;
          draft.search.sections = mapSearchFormBySection(draft.search.items);
        }
      }),
    [CANCEL_SEARCH_FORM + START]: (state) =>
      produce(state, (draft) => {
        draft.search.items = state.search.originItems;
        draft.search.sections = mapSearchFormBySection(draft.search.items);
      }),
    [RESET_SEARCH_FORM + START]: (state) =>
      produce(state, (draft) => {
        draft.search.loading = true;
        draft.search.success = false;
        draft.search.error = null;
      }),
    [RESET_SEARCH_FORM + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.search.loading = false;
        draft.search.success = true;
        draft.search.error = null;
      }),
    [RESET_SEARCH_FORM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.search.loading = false;
        draft.search.success = false;
        draft.search.error = payload;
      }),
    [SUBMIT_SEARCH_FORM + START]: (state) =>
      produce(state, (draft) => {
        draft.search.loading = true;
        draft.search.success = false;
        draft.search.error = null;
      }),
    [SUBMIT_SEARCH_FORM + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.search.loading = false;
        draft.search.success = true;
        draft.search.error = null;
      }),
    [SUBMIT_SEARCH_FORM + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.search.loading = false;
        draft.search.success = false;
        draft.search.error = payload;
      }),
    [SET_TAB_COUNTS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.tabCounts = payload;
      }),
    [INIT_ASK_PROPERTY]: (state) =>
      produce(state, (draft) => {
        draft.askProperty = initialState.askProperty;
      }),
    [combineActions(ASK_PROPERTY_TOUR + START, ASK_PROPERTY_MORE_INFO + START, ASK_PROPERTY_FINANCING + START)]: (state) =>
      produce(state, (draft) => {
        draft.askProperty.loading = true;
        draft.askProperty.success = false;
        draft.askProperty.error = null;
      }),
    [combineActions(ASK_PROPERTY_TOUR + SUCCESS, ASK_PROPERTY_MORE_INFO + SUCCESS, ASK_PROPERTY_FINANCING + SUCCESS)]: (state) =>
      produce(state, (draft) => {
        draft.askProperty.loading = false;
        draft.askProperty.success = true;
        draft.askProperty.error = null;
      }),
    [combineActions(ASK_PROPERTY_TOUR + FAIL, ASK_PROPERTY_MORE_INFO + FAIL, ASK_PROPERTY_FINANCING + FAIL)]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.askProperty.loading = false;
        draft.askProperty.success = false;
        draft.askProperty.error = payload;
      }),
  },
  initialState,
);
