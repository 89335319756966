import { createAction } from 'redux-actions';
import { START } from '../common';

export const FETCH_PROPERTY = 'FETCH_PROPERTY';
export const SET_TAB_COUNTS = 'SET_TAB_COUNTS';
export const FETCH_PROPERTY_INFO = 'FETCH_PROPERTY_INFO';
export const RESET_PROPERTY_INFO = 'RESET_PROPERTY_INFO';
export const PRESELECT_PROPERTY = 'PRESELECT_PROPERTY';
export const UPDATE_PROPERTY_FILTER = 'UPDATE_PROPERTY_FILTER';
export const UPDATE_MAP_CENTER = 'UPDATE_MAP_CENTER';
export const UPDATE_MAP_ZOOM = 'UPDATE_MAP_ZOOM';
export const SET_MAP_DRAW_MODE = 'SET_MAP_DRAW_MODE';
export const SET_MAP_READY = 'SET_MAP_READY';
export const CLEAR_MAP = 'CLEAR_MAP';
export const TOGGLE_SEARCH_FILTER = 'TOGGLE_SEARCH_FILTER';
export const TOGGLE_LIST = 'TOGGLE_LIST';
export const FETCH_SEARCH_OPTION = 'FETCH_SEARCH_OPTION';
export const HANDLE_SEARCH_OPTION_CHANGE = 'HANDLE_SEARCH_OPTION_CHANGE';
export const CANCEL_SEARCH_FORM = 'CANCEL_SEARCH_FORM';
export const RESET_SEARCH_FORM = 'RESET_SEARCH_FORM';
export const SUBMIT_SEARCH_FORM = 'SUBMIT_SEARCH_FORM';
export const LIKE_PROPERTY = 'LIKE_PROPERTY';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const INIT_ASK_PROPERTY = 'INIT_ASK_PROPERTY';
export const ASK_PROPERTY_TOUR = 'ASK_PROPERTY_TOUR';
export const ASK_PROPERTY_MORE_INFO = 'ASK_PROPERTY_MORE_INFO';
export const ASK_PROPERTY_FINANCING = 'ASK_PROPERTY_FINANCING';

export const fetchPropertyAction = createAction(FETCH_PROPERTY + START);
export const fetchPropertyInfoAction = createAction(FETCH_PROPERTY_INFO + START);
export const resetPropertyInfoAction = createAction(RESET_PROPERTY_INFO);
export const preSelectPropertyAction = createAction(PRESELECT_PROPERTY);
export const updatePropertyFilterAction = createAction(UPDATE_PROPERTY_FILTER);
export const updateMapCenterAction = createAction(UPDATE_MAP_CENTER);
export const updateMapZoomAction = createAction(UPDATE_MAP_ZOOM);
export const setMapDrawModeAction = createAction(SET_MAP_DRAW_MODE);
export const setMapReadyAction = createAction(SET_MAP_READY);
export const clearMapAction = createAction(CLEAR_MAP);
export const toggleSearchFilterAction = createAction(TOGGLE_SEARCH_FILTER);
export const toggleListAction = createAction(TOGGLE_LIST);
export const fetchSearchOptionAction = createAction(FETCH_SEARCH_OPTION + START);
export const handleSearchOptionChangeAction = createAction(HANDLE_SEARCH_OPTION_CHANGE);
export const cancelSearchFormAction = createAction(CANCEL_SEARCH_FORM + START);
export const resetSearchFormAction = createAction(RESET_SEARCH_FORM + START);
export const submitSearchFormAction = createAction(SUBMIT_SEARCH_FORM + START);
export const likePropertyAction = createAction(LIKE_PROPERTY + START);
export const addPropertyAction = createAction(ADD_PROPERTY + START);
export const initAskPropertyAction = createAction(INIT_ASK_PROPERTY);
export const askPropertyTour = createAction(ASK_PROPERTY_TOUR + START);
export const askPropertyMoreInfo = createAction(ASK_PROPERTY_MORE_INFO + START);
