import { all, fork } from 'redux-saga/effects';

import userSaga from './user/saga';
import propertySaga from './property/saga';
import chatSaga from './chat/saga';
import activitySaga from './activity/saga';

export default function* rootSaga() {
  yield all([fork(userSaga), fork(propertySaga), fork(chatSaga), fork(activitySaga)]);
}
