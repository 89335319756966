import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { LoadingView } from '../LoadingView';
import { BaseModal } from './BaseModal';
import { Button, CheckBox, FlexCol, FlexRow, HR, Label, PhoneNumberInput } from '..';
import { userSelector } from '_app/store/user/selector';
import { askPropertyMoreInfo, initAskPropertyAction } from '_app/store/property/action';
import { getPropertyAskSelector } from '_app/store/property/selector';

const OPTIONS = ["Seller's Disclosure", "Agent's Opinion", 'Flood History', 'Other'];

export const PropertyMoreInfoActionModal = ({ visible, mlsnum, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { loading, success } = useSelector(getPropertyAskSelector);
  const [phone, setPhone] = useState(user.phone || '');
  const [otherText, setOtherText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    if (visible) {
      dispatch(initAskPropertyAction());
      setPhone(user.phone || '');
      setSelectedOptions('');
      setOtherText('');
    }
  }, [visible]);

  useEffect(() => {
    if (!loading && success) {
      onClose();
    }
  }, [loading, success]);

  const onClickItem = (item) => {
    if (selectedOptions.includes(item)) {
      setSelectedOptions(selectedOptions.filter((sItem) => sItem !== item));
    } else {
      setSelectedOptions([...selectedOptions, item]);
    }
  };

  const disabled = !isValidPhoneNumber(phone, 'US') || !selectedOptions.length || (selectedOptions.includes('Other') && !otherText.trim());

  const onSubmit = () => {
    const options = selectedOptions.filter((option) => option !== 'Other');
    dispatch(askPropertyMoreInfo({ mlsnum, phone, dates_and_times: !otherText.trim() ? options : [...options, otherText] }));
  };

  return (
    <BaseModal visible={visible} onClose={onClose} overlayClassName="items-center" className="my-auto w-screen max-w-screen-sm px-2">
      <FlexCol className="bg-white w-full pb-2 rounded">
        <FlexRow className="p-4 items-center justify-between flex-shrink-0">
          <FlexRow className="items-center">
            <Label label="Need More Information?" className="text-primary5 text-lg font-primary-bold" />
          </FlexRow>
          <FontAwesomeIcon icon={['fas', 'times']} className="text-lg cursor-pointer text-primary5 mr-1" onClick={onClose} />
        </FlexRow>
        <HR className="flex-shrink-0" />
        <FlexCol className="px-5 py-6">
          <FlexCol>
            <Label label="1. What’s The Best Phone Number For You To Get Details?" className="text-lg font-primary text-primary3" />
            <PhoneNumberInput value={phone} onChange={setPhone} />
          </FlexCol>
          <FlexCol className="mt-10">
            <Label label="2. What Information Is Most Helpful For You?" className="text-lg font-primary text-primary3" />
            <FlexCol className="mx-4">
              {OPTIONS.map((item, index) => (
                <FlexRow key={index} className="py-2 cursor-pointer" onClick={() => onClickItem(item)}>
                  <CheckBox checked={selectedOptions.includes(item)} />
                  <Label label={item} className="ml-2 font-primary-bold text-base" />
                </FlexRow>
              ))}
              {selectedOptions.includes('Other') && (
                <textarea
                  className={`border border-primary1 rounded h-20 outline-none px-2 py-1 mt-2 ml-6`}
                  value={otherText}
                  placeholder="Please put your request here..."
                  onChange={(e) => setOtherText(e.target.value)}
                />
              )}
            </FlexCol>
          </FlexCol>
          <FlexRow className="mt-8 justify-end w-full">
            <Button
              label="Submit"
              className={`text-white px-8 py-1 ${disabled ? 'bg-gray-500' : 'bg-primary2'}`}
              onClick={() => !disabled && onSubmit()}
            />
          </FlexRow>
        </FlexCol>
      </FlexCol>
      {loading && <LoadingView />}
    </BaseModal>
  );
};
