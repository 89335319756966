import React from 'react';

const NotFound = () => {
  return (
    <div className="flex flex-1 justify-center items-center">
      <div className="text-primary2 text-lg">404 Not Found</div>
    </div>
  );
};

export default NotFound;
