import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { handleSearchOptionChangeAction } from '_app/store/property/action';

import { FlexRow } from '../FlexRow';

const TYPE_IMAGE = {
  'Single Family': 'https://www.har.com/resources/images/listing_details/single-family.png',
  'Multi Family': 'https://www.har.com/resources/images/listing_details/midhi-rise.png',
  Townhome: 'https://www.har.com/resources/images/listing_details/townhouse-condo.png',
  Lots: 'https://www.har.com/resources/images/listing_details/multi-family.png',
  'Highrise Condo': 'https://www.har.com/resources/images/listing_details/country-homes.png',
  Acreage: 'https://www.har.com/resources/images/listing_details/residential-lots.png',
};

export const PropertyTypeSelector = ({ item }) => {
  const dispatch = useDispatch();
  const onChangeValue = (name, value) => {
    const originValue = item.value || [];
    const newValue = item.value && item.value.includes(value) ? originValue.filter((sValue) => sValue !== value) : [...originValue, value];
    const newItem = { ...item, value: newValue };
    dispatch(handleSearchOptionChangeAction(newItem));
  };
  return (
    <FlexRow className="rounded-lg overflow-hidden mt-2 grid grid-flow-row grid-cols-3 lg:grid-cols-6 w-full sm:w-3/4 flex-1">
      {item.choices.map(({ label, value }, index) => (
        <div
          key={index}
          checked={item.value && item.value.includes(value)}
          className="h-20 relative m-px overflow-hidden flex p-1 cursor-pointer"
          onClick={() => onChangeValue(item.name, value)}>
          <img src={TYPE_IMAGE[label]} className="absolute inset-0 object-cover h-full w-full" alt="family image" />
          <div className="absolute inset-0 h-full w-full bg-black bg-opacity-50" />
          {item.value && item.value.includes(value) && (
            <div className="absolute inset-0 h-full w-full bg-primary1 bg-opacity-20 flex items-end justify-end">
              <FontAwesomeIcon icon={['fas', 'check']} className="text-2xl text-white mr-2 mb-2" />
            </div>
          )}
          <span className="text-white font-primary-bold leading-5 z-20">{label}</span>
        </div>
      ))}
    </FlexRow>
  );
};
