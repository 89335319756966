import React, { createRef, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPropertyAction, likePropertyAction } from '_app/store/property/action';
import { NotificationComponent } from '.';

import { PropertyListItem } from './PropertyListItem';

export const PropertyList = ({ preSelected, items = [], onClickItem = () => {}, onPreSelectItem = () => {}, toggleList = false }) => {
  const dispatch = useDispatch();
  const scrollRef = useRef();
  const refs = items.reduce((acc, value) => {
    acc[value.mlsnum] = createRef();
    return acc;
  }, {});

  const itemContainerClass = toggleList
    ? 'w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2 cursor-pointer bg-white hover:bg-primary1 hover:text-white'
    : 'w-full lg:w-1/2 p-2 cursor-pointer bg-white hover:bg-primary1 hover:text-white';

  useEffect(() => {
    if (scrollRef && scrollRef.current);
    scrollRef.current.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (preSelected && preSelected.shouldScrollToItem && refs[preSelected.mlsnum] && refs[preSelected.mlsnum].current) {
      refs[preSelected.mlsnum].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [preSelected, refs]);

  const handleScroll = () => {
    if (scrollRef.current.scrollTop + scrollRef.current.clientHeight < scrollRef.current.scrollHeight - 1) {
      return;
    }
    loadMore();
  };

  const loadMore = () => {
    dispatch(fetchPropertyAction(false));
  };

  const onLike = (item) => {
    const notification = (
      <NotificationComponent
        text={`${item.address} added to Favs. `}
        undoAction={() => {
          dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating, notification }));
        }}
      />
    );
    dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating === 1 ? 0 : 1, notification }));
  };

  const onDisLike = (item) => {
    const notification = (
      <NotificationComponent
        text={`${item.address} added to Reject. `}
        undoAction={() => {
          dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating, notification }));
        }}
      />
    );
    dispatch(likePropertyAction({ mlsnum: item.mlsnum, address: item.address, status: item.rating === -1 ? 0 : -1, notification }));
  };

  return (
    <>
      <div ref={scrollRef} className="flex-wrap absolute top-0 left-0 right-0 bottom-0 pt-2 overflow-y-auto flex content-start pb-16">
        {items.map((item, index) => (
          <div
            className={`${itemContainerClass} ${preSelected && preSelected.mlsnum === item.mlsnum ? 'bg-primary1 text-white' : ''}`}
            key={index}
            ref={refs[item.mlsnum]}>
            <PropertyListItem
              key={index}
              item={item}
              preSelected={preSelected && preSelected.mlsnum === item.mlsnum}
              onMouseEnter={() => onPreSelectItem(item)}
              onClick={() => onClickItem(item)}
              onLike={() => onLike(item)}
              onDisLike={() => onDisLike(item)}
            />
          </div>
        ))}
      </div>
    </>
  );
};
