import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckSquare,
  faCoffee,
  faHome,
  faQuestionCircle,
  faShareAlt,
  faCopy,
  faExternalLinkAlt,
  faChevronUp,
  faChevronDown,
  faTimes,
  faCheck,
  faBars,
  faComments,
  faSearch,
  faPenSquare,
  faEdit,
  faThumbsUp,
  faThumbsDown,
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faCheckSquare,
  faCoffee,
  faShareAlt,
  faHome,
  faQuestionCircle,
  faShareAlt,
  faCopy,
  faExternalLinkAlt,
  faChevronUp,
  faChevronDown,
  faTimes,
  faCheck,
  faBars,
  faComments,
  faSearch,
  faPenSquare,
  faEdit,
  faThumbsUp,
  faThumbsDown,
);
