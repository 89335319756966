import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import Utils from '_app/utils';
import APIService from '_app/services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PropertySearchAutoComplete = ({ onSelectAddress = () => {} }) => {
  const [value, setValue] = useState('');
  const [list, setList] = useState([]);
  const { ref, clickedOutside, setClickedOutside } = Utils.useOutSideClick(true);

  useEffect(() => {
    setClickedOutside(list.length === 0);
  }, [list, setClickedOutside]);

  const onSelectPlaceList = (item) => {
    setList([]);
    setValue('');
    onSelectAddress(item);
  };

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      onSelectPlaceList({ mlsnum: value, address: null });
    }
  };

  const getAddress = useMemo(
    () =>
      debounce(async (input) => {
        if (!input) {
          setList([]);
          return;
        }
        const { data: results } = await APIService.get(`/properties/manually-add/?search=${input}`);
        if (results && results.results) {
          setList(results.results);
        } else {
          setList([]);
        }
      }, 500),
    [],
  );

  return (
    <div className="p-2 relative z-50 bg-primary2">
      <input
        type="text"
        placeholder="Add Address or MLS #"
        className="bg-white rounded px-2 w-full h-8 outline-none font-primary"
        value={value || ''}
        onKeyDown={handleKeydown}
        onChange={({ target }) => {
          getAddress(target.value);
          setValue(target.value);
        }}
      />
      {!!value && (
        <div
          className="p-3 cursor-pointer absolute top-0 right-2"
          onClick={() => {
            setList([]);
            setValue('');
          }}>
          <FontAwesomeIcon icon={['fas', 'times']} className="text-sm text-primary2" />
        </div>
      )}
      <div
        ref={ref}
        className={`absolute top-14 left-2 py-1 border bg-white shadow-2xl rounded overflow-hidden w-11/12 max-w-sm max-h-96 overflow-y-auto ${
          clickedOutside ? 'hidden' : ''
        }`}>
        {list.map((item, index) => (
          <div
            className="py-2 px-4 pr-6 cursor-pointer transition duration-500 ease-in-out bg-white hover:bg-primary2 hover:text-white flex flex-row justify-between items-center"
            onClick={() => onSelectPlaceList(item)}
            key={index}>
            <span>{item.address}</span>
            <span className="text-white font-bold text-xl">+</span>
          </div>
        ))}
      </div>
    </div>
  );
};
