import React from 'react';

export const Input = ({ type = 'text', value = '', placeholder = '', containerClassName = '', className = '', icon = null, onChange = () => {} }) => {
  return (
    <div className={'flex-1 relative ' + containerClassName}>
      <input
        type={type}
        placeholder={placeholder}
        className={`w-full border p-2 border-gray-100 rounded ${className}`}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {!!icon && <div className="absolute left-0 top-0 bottom-0 flex justify-center items-center">{icon}</div>}
    </div>
  );
};
