import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LoadingView } from '../LoadingView';
import { BaseModal } from './BaseModal';

import { Button, FlexCol, FlexRow, Label } from '..';

export const ConfirmModal = ({ visible, title = '', description = '', loading, onConfirm = () => {}, onClose = () => {} }) => {
  return (
    <BaseModal visible={visible} className="flex w-full px-4 sm:w-96 sm:px-0 outline-none font-primary justify-center items-center" onClose={onClose}>
      <FlexCol className="bg-white py-4 px-6 rounded-lg">
        <FlexRow className="my-2 justify-between items-center">
          <Label label={title} className="text-xl" />
          <FontAwesomeIcon icon={['fas', 'times']} className="text-black text-xl cursor-pointer" onClick={onClose} />
        </FlexRow>
        <FlexCol className="py-4">
          <div>{description}</div>
        </FlexCol>
        <FlexRow className="justify-end items-center mt-4">
          <Button label="Cancel" className="w-20 py-1 justify-center mr-4 border" labelStyle="text-base" onClick={onClose} />
          <Button label="OK" className="bg-primary2 w-20 py-1 justify-center" labelStyle="text-white text-base" onClick={onConfirm} />
        </FlexRow>
      </FlexCol>
      {loading && <LoadingView />}
    </BaseModal>
  );
};
