import React, { useEffect, useRef } from 'react';
import Carousel from 'nuka-carousel';

export const ImageSlider = ({
  images,
  slideIndex = 0,
  enableDragging = true,
  imageContainer,
  imageClassName = '',
  rightButton = null,
  leftButton = null,
  onClick = () => {},
}) => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.handleFocus();
    }
  }, []);
  return (
    <div
      className="w-full h-full"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}>
      <Carousel
        ref={ref}
        renderBottomCenterControls={() => null}
        dragging={enableDragging}
        slideIndex={slideIndex}
        wrapAround={true}
        enableKeyboardControls={true}
        renderCenterLeftControls={leftButton}
        renderCenterRightControls={rightButton}>
        {images.map((image, index) => (
          <div key={index} className={imageContainer}>
            <img src={image.url} alt="property-image" className={`object-cover ${imageClassName}`} />
            {enableDragging && <div className="text-white text-base absolute bottom-2 text-center w-full">{image.caption}</div>}
          </div>
        ))}
      </Carousel>
    </div>
  );
};
