import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorBoundary } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import configureStore, { history } from '_app/store';

import 'react-phone-number-input/style.css';
import './styles/tailwind.css';
import './styles/global.css';
import './styles/custom.css';
import 'react-toastify/dist/ReactToastify.css';
import '_app/styles/fontAwesome';

import Pages from '_app/pages';
import { Env } from './config';
import { ErrorReport } from './components';

Sentry.init({
  dsn: Env.SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
});

const App = () => {
  const [rootStore, setRootStore] = useState(undefined);

  useEffect(() => {
    const initStore = async () => {
      const { store, persistor } = await configureStore();
      setRootStore({ store, persistor });
    };
    initStore();
  }, []);

  if (!rootStore) {
    return null;
  }

  return (
    <Provider store={rootStore?.store}>
      <ErrorBoundary
        fallback={({ error, componentStack, resetError }) => <ErrorReport error={error} componentStack={componentStack} resetError={resetError} />}>
        <PersistGate loading={null} persistor={rootStore.persistor}>
          <ConnectedRouter history={history}>
            <Pages />
            <ToastContainer theme="colored" />
          </ConnectedRouter>
        </PersistGate>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
