import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from './Button';
import { Label } from './Label';
import { DrawingGuard } from './DrawingGuard';

import { ReactComponent as SearchIcon } from '_app/assets/images/search.svg';

import { getPropertySelector } from '_app/store/property/selector';
import { setMapDrawModeAction, toggleListAction, toggleSearchFilterAction } from '_app/store/property/action';
import { SearchModeSelector } from './SeachForms';
import { FlexCol, FlexRow } from '.';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OpenHousesModal } from './modals';
import { Link } from 'react-router-dom';
import { publicIdSelector, userSelector } from '_app/store/user/selector';
import { copyToClipboard } from '_app/utils/copyToClipboard';
import { showInfoToast } from '_app/utils/toast';

export const ToolBar = ({ visibleSearch = false, visibleList = false, toggleActivity = () => {} }) => {
  const dispatch = useDispatch();
  const publicId = useSelector(publicIdSelector);
  const [showOpenHouse, setShowOpenHouse] = useState(false);
  const { isMapDrawing, total, toggleSearchFilter, items, filter, tabCounts } = useSelector(getPropertySelector);
  const user = useSelector(userSelector);
  const isStaff = user && !!user.staff_session;

  const onCopy = () => {
    if (!items.length) {
      return;
    }
    const mlsArray = tabCounts[filter.tab.tabId + '_mlsnums'] || [];
    copyToClipboard(mlsArray.join(', '));
    showInfoToast(mlsArray.length + " MLS #'s copied to your clipboard");
  };
  return (
    <div className="w-full h-12 flex-row flex border-b">
      <div
        className={`flex flex-row justify-between items-center p-2 sm:w-1/2 lg:w-1/2 xl:w-1/3 relative ${
          visibleSearch ? 'sm:border-r-0' : 'sm:border-r'
        }`}>
        <div className="flex-row flex items-center">
          <Link to={toggleSearchFilter ? `/${publicId}/listing/new/` : `/${publicId}/search-form/`}>
            <Button
              label="FILTERS"
              leftIcon={<SearchIcon fill="white" />}
              className="bg-primary2 text-sm text-white p-1 px-3 rounded"
              onClick={() => dispatch(toggleSearchFilterAction())}
            />
          </Link>
          {total > 0 && <Label label={`${total} RESULTS`} className="ml-4 hidden md:flex text-primary5" />}
          {total > 0 && isStaff && (
            <FontAwesomeIcon icon={['fas', 'copy']} className="ml-4 text-xl text-yellow-600 cursor-pointer" onClick={onCopy} />
          )}
        </div>
        {!visibleSearch && (
          <div className="flex-row items-center hidden sm:flex">
            <Button label={visibleList ? 'Map' : 'LIST'} className="px-2 text-primary5" onClick={() => dispatch(toggleListAction())} />
          </div>
        )}
        <DrawingGuard />
      </div>
      {visibleSearch && (
        <FlexCol className="flex-1 justify-center items-end mr-2">
          <SearchModeSelector />
        </FlexCol>
      )}
      {!visibleSearch && (
        <div className="flex-1 w-full flex h-full flex-row justify-between items-center">
          {!visibleList && (
            <div className="flex-row flex items-center">
              <Button
                label={isMapDrawing ? 'CANCEL DRAW' : 'DRAW'}
                className="px-4"
                labelStyle="text-primary5"
                onClick={() => dispatch(setMapDrawModeAction(!isMapDrawing))}
              />
            </div>
          )}
          <div className="flex-row flex items-center relative">
            <Button
              label={visibleList ? 'Show Map' : 'LIST'}
              className="mr-2 px-2 sm:hidden"
              labelStyle="text-primary5"
              onClick={() => dispatch(toggleListAction())}
            />
            <DrawingGuard />
          </div>
          <FlexRow>
            <FlexRow className="px-2 items-center" onClick={() => setShowOpenHouse(true)}>
              <FontAwesomeIcon icon={['fas', 'home']} className="text-base sm:text-sm text-primary5 mr-1" />
              <Button label="OPEN HOUSES" className="text-primary5 hidden sm:flex outline-none" />
            </FlexRow>
            <FlexRow className="px-2 items-center" onClick={toggleActivity}>
              <FontAwesomeIcon icon={['fas', 'share-alt']} className="text-base sm:text-sm text-primary5 mr-1" />
              <Button label="UPDATES" className="text-primary5 hidden sm:flex outline-none" />
            </FlexRow>
            {isStaff && !!user.profile_url && (
              <a href={user.profile_url} rel="noreferrer" target="_blank">
                <FlexRow className="px-2 items-center cursor-pointer">
                  <span className="text-yellow-600 mr-1">
                    {user.name} {user.surname}
                  </span>
                  <FontAwesomeIcon icon={['fas', 'edit']} className="text-base sm:text-sm text-yellow-600 mr-2" />
                </FlexRow>
              </a>
            )}
          </FlexRow>
        </div>
      )}
      <OpenHousesModal visible={showOpenHouse} onClose={() => setShowOpenHouse(false)} />
    </div>
  );
};
