import { createAction } from 'redux-actions';
import { START } from '../common';

export const TOGGLE_CHAT = 'TOGGLE_CHAT';
export const FETCH_CHAT = 'FETCH_CHAT';
export const SEND_CHAT = 'SEND_CHAT';

export const toggleChatAction = createAction(TOGGLE_CHAT);
export const fetchChatAction = createAction(FETCH_CHAT + START);
export const sendChatAction = createAction(SEND_CHAT + START);
