import APIService from '_app/services/api';
import Utils from '_app/utils';
import axios from 'axios';
import { all, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { createMatchSelector, push } from 'connected-react-router';

import { CANCEL, FAIL, START, SUCCESS } from '../common';
import {
  ADD_PROPERTY,
  ASK_PROPERTY_MORE_INFO,
  ASK_PROPERTY_TOUR,
  CLEAR_MAP,
  FETCH_PROPERTY,
  FETCH_PROPERTY_INFO,
  FETCH_SEARCH_OPTION,
  LIKE_PROPERTY,
  RESET_PROPERTY_INFO,
  RESET_SEARCH_FORM,
  SET_TAB_COUNTS,
  SUBMIT_SEARCH_FORM,
  TOGGLE_SEARCH_FILTER,
  UPDATE_PROPERTY_FILTER,
} from './action';
import { getPropertyFilterSelector, getPropertySelector, searchOptionSelector } from './selector';
import ROUTES from '_app/pages/routes';
import { publicIdSelector } from '../user/selector';
import { FETCH_CHAT } from '../chat/action';
import { FETCH_PROFILE } from '../user/action';
import { generateMapPolygonUpdateFormData } from '_app/utils/converter';

let pfCancelToken = undefined;

function* fetchProperties({ payload: initial = true }) {
  const state = yield select();
  const { filter, hasMore, page } = getPropertySelector(state);
  const { tab, bounds } = filter;

  if (!hasMore && !initial) {
    yield put({ type: FETCH_PROPERTY + CANCEL });
    return;
  }

  const currentPage = initial ? 1 : page + 1;

  if (typeof pfCancelToken != typeof undefined) {
    pfCancelToken.cancel('Operation canceled due to new request.');
  }
  pfCancelToken = axios.CancelToken.source();
  try {
    const { data } = yield APIService.get(`/properties/${Utils.generateMapSearchParams(tab.apiEndpoint, bounds, false, currentPage)}`, {
      cancelToken: pfCancelToken.token,
    });
    yield put({ type: FETCH_PROPERTY + SUCCESS, payload: { ...data, page: currentPage } });
    const { data: tabData } = yield APIService.get(`/properties/tab-counts/${Utils.generateTabCountParams(bounds, false)}`);
    yield put({ type: SET_TAB_COUNTS, payload: tabData });
  } catch (error) {
    yield put({ type: FETCH_PROPERTY + FAIL, payload: error });
    Utils.showErrorToast(Utils.getError(error).message);
  }
}

function* fetchPropertyInfo() {
  while (true) {
    const { payload: mlsnum } = yield take(FETCH_PROPERTY_INFO + START);
    try {
      const { data } = yield APIService.get(`/properties/${mlsnum}/`);
      yield put({ type: FETCH_PROPERTY_INFO + SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: FETCH_PROPERTY_INFO + FAIL, payload: error });
      const errorMessage = Utils.getError(error).message;
      if (errorMessage.includes('not found')) {
        Utils.showErrorToast('This property is no longer available.');
      } else {
        Utils.showErrorToast(Utils.getError(error).message);
      }
      yield put({ type: RESET_PROPERTY_INFO });
      yield put({ type: FETCH_PROPERTY + START });

      const state = yield select();
      const matchSelector = createMatchSelector(ROUTES[2]);
      const publicId = publicIdSelector(state);
      const match = matchSelector(state);
      // redirect new tab when it's on property detail page
      if (match && match.params && match.params.propertyId) {
        yield put(push(`/${publicId}/listing/new/`));
      }
    }
  }
}

function* fetchSearchOptions() {
  while (true) {
    yield take(FETCH_SEARCH_OPTION + START);
    try {
      const { data } = yield APIService.get('/search-form/');
      yield put({ type: FETCH_SEARCH_OPTION + SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: FETCH_SEARCH_OPTION + FAIL, payload: error });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* likeProperty() {
  while (true) {
    const {
      payload: { mlsnum, status, notification },
    } = yield take(LIKE_PROPERTY + START);
    try {
      const body = new FormData();
      body.append('mlsnum', mlsnum);
      if (status === 1 || status === -1) {
        body.append('rating', status);
      }
      if (status === 1 || status === -1) {
        const { data } = yield APIService.post(`/properties/${mlsnum}/rate/`, body);
        yield put({ type: LIKE_PROPERTY + SUCCESS, payload: data });
      } else {
        const { data } = yield APIService.delete(`/properties/${mlsnum}/rate/`, body);
        yield put({ type: LIKE_PROPERTY + SUCCESS, payload: data });
      }
      if (status === 1) {
        Utils.showInfoToast(notification);
      } else if (status === -1) {
        Utils.showInfoToast(notification);
      }

      const state = yield select();
      const matchSelector = createMatchSelector(ROUTES[1]);
      const match = matchSelector(state);
      // only sync properties on main search page
      if (match && match.params && match.params.tab) {
        yield put({ type: FETCH_PROPERTY + START });
      }

      yield put({ type: FETCH_PROPERTY + START });
      yield put({ type: FETCH_PROPERTY_INFO + START, payload: mlsnum });
    } catch (error) {
      yield put({ type: LIKE_PROPERTY + FAIL, payload: error });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* resetSearchForm() {
  while (true) {
    yield take(RESET_SEARCH_FORM + START);
    try {
      const body = new FormData();
      const { data } = yield APIService.post('/search-form/', body);
      yield put({ type: RESET_SEARCH_FORM + SUCCESS, payload: data });
      yield put({ type: FETCH_SEARCH_OPTION + START });
      yield put({ type: FETCH_PROPERTY + START });
      Utils.showInfoToast('Successfully updated property search.');
    } catch (error) {
      yield put({ type: RESET_SEARCH_FORM + FAIL, payload: error });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* submitSearchForm() {
  while (true) {
    yield take(SUBMIT_SEARCH_FORM + START);
    try {
      const state = yield select();
      const publicId = publicIdSelector(state);
      const { items } = searchOptionSelector(state);
      const body = new FormData();
      items
        .filter((item) => !!item.value || item.value == '0')
        .forEach((item) => {
          if (Array.isArray(item.value)) {
            item.value.forEach((val) => {
              body.append(`${item.name}`, val);
            });
          } else {
            body.append(item.name, item.value);
          }
        });
      const { data } = yield APIService.post('/search-form/', body);
      yield put({ type: RESET_SEARCH_FORM + SUCCESS, payload: data });
      yield put({ type: FETCH_SEARCH_OPTION + START });
      yield put({ type: FETCH_PROPERTY + START });
      yield put({ type: TOGGLE_SEARCH_FILTER, payload: false });
      yield put(push(`/${publicId}/listing/new/`));
      Utils.showInfoToast('Successfully updated property search.');
    } catch (error) {
      yield put({ type: RESET_SEARCH_FORM + FAIL, payload: error });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* addProperty() {
  while (true) {
    const { payload } = yield take(ADD_PROPERTY + START);
    try {
      const body = new FormData();
      body.append('mlsnums', payload.mlsnum);
      const { data } = yield APIService.post('/properties/manually-add/', body);
      yield put({ type: ADD_PROPERTY + SUCCESS, payload: data });
      yield put({ type: FETCH_PROPERTY + START });
      Utils.showInfoToast(`Added ${payload.address || `${data.added.length} properties`} to new.`);
    } catch (error) {
      yield put({ type: ADD_PROPERTY + FAIL, payload: error });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* askPropertyTour() {
  while (true) {
    const { payload } = yield take(ASK_PROPERTY_TOUR + START);
    try {
      const body = new FormData();
      body.append('phone', payload.phone);
      body.append('dates_and_times', payload.date);
      const { data } = yield APIService.post(`/properties/${payload.mlsnum}/tour/`, body);
      yield put({ type: ASK_PROPERTY_TOUR + SUCCESS, payload: data });
      yield put({ type: FETCH_PROFILE + START });
      yield put({ type: FETCH_CHAT + START, payload: payload.mlsnum });
      Utils.showInfoToast('Successfully contacted owner.');
    } catch (error) {
      yield put({ type: ASK_PROPERTY_TOUR + FAIL, payload: error });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* askPropertyMoreInfo() {
  while (true) {
    const { payload } = yield take(ASK_PROPERTY_MORE_INFO + START);
    try {
      const body = new FormData();
      body.append('phone', payload.phone);
      payload.dates_and_times.forEach((value) => {
        body.append('most_helpful', value);
      });
      const { data } = yield APIService.post(`/properties/${payload.mlsnum}/get-more-info/`, body);
      yield put({ type: ASK_PROPERTY_MORE_INFO + SUCCESS, payload: data });
      yield put({ type: FETCH_PROFILE + START });
      yield put({ type: FETCH_CHAT + START, payload: payload.mlsnum });
      Utils.showInfoToast('Successfully contacted owner.');
    } catch (error) {
      yield put({ type: ASK_PROPERTY_MORE_INFO + FAIL, payload: error });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* deleteMapPolygons() {
  while (true) {
    yield take(CLEAR_MAP);
    try {
      yield APIService.delete(`/properties/map-polygons/`);
      yield put({ type: FETCH_PROPERTY + START });
    } catch (error) {
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* updateMapPolygons() {
  while (true) {
    const { payload } = yield take(UPDATE_PROPERTY_FILTER);
    if (payload.key === 'bounds' && payload.shouldUpdateApi) {
      try {
        const state = yield select();
        const { bounds } = getPropertyFilterSelector(state);
        const body = generateMapPolygonUpdateFormData(bounds);
        yield APIService.put(`/properties/map-polygons/`, body);
        yield put({ type: FETCH_PROPERTY + START });
      } catch (error) {
        Utils.showErrorToast(Utils.getError(error).message);
      }
    } else {
      continue;
    }
  }
}

export default function* sagas() {
  yield all([
    takeLatest([FETCH_PROPERTY + START], fetchProperties),
    fork(fetchPropertyInfo),
    fork(fetchSearchOptions),
    fork(likeProperty),
    fork(resetSearchForm),
    fork(submitSearchForm),
    fork(addProperty),
    fork(askPropertyTour),
    fork(askPropertyMoreInfo),
    fork(deleteMapPolygons),
    fork(updateMapPolygons),
  ]);
}
