import { createSelector } from 'reselect';

const getPropertyState = ({ property }) => property;

export const getPropertySelector = createSelector([getPropertyState], (property) => property);
export const getPreSelectedPropertySelector = createSelector([getPropertyState], ({ preSelected }) => preSelected);
export const getSelectedPropertySelector = createSelector([getPropertyState], ({ selected }) => selected);
export const getPropertyFilterSelector = createSelector([getPropertyState], ({ filter }) => filter);
export const mapCenterSelector = createSelector([getPropertyState], ({ mapCenter }) => mapCenter);
export const mapZoomSelector = createSelector([getPropertyState], ({ mapZoom }) => mapZoom);
export const toggleSearchFilterSelector = createSelector([getPropertyState], ({ toggleSearchFilter }) => toggleSearchFilter);
export const toggleListSelector = createSelector([getPropertyState], ({ toggleList }) => toggleList);
export const searchOptionSelector = createSelector([getPropertyState], ({ search }) => search);
export const simpleSearchFormSelector = createSelector([searchOptionSelector], ({ items }) => items.find((item) => item.name === 'simple_search'));
export const getPropertyAskSelector = createSelector([getPropertyState], ({ askProperty }) => askProperty);
