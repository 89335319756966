import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from './Button';
import { fetchChatAction, toggleChatAction } from '_app/store/chat/action';
import { PropertyMoreInfoActionModal, PropertyTourActionModal } from './modals';
import APIService from '_app/services/api';
import Utils from '_app/utils';

export const PropertyInfoActions = ({ mlsnum }) => {
  const [showTour, setShowTour] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <Button
        label="Tour"
        className="bg-primary2 w-3/12 items-center justify-center"
        labelStyle="text-white font-primary-bold text-base"
        onClick={() => setShowTour(true)}
      />
      <Button
        label="More Info"
        className="bg-primary2 w-3/12 items-center justify-center"
        labelStyle="text-white font-primary-bold text-base"
        onClick={() => setShowMoreInfo(true)}
      />
      <Button
        label="Get Pre-Approved"
        className="bg-yellow-600 w-3/12 items-center justify-center"
        labelStyle="text-white font-primary-bold text-base"
        onClick={() => preApprovalClickAction(mlsnum, dispatch)}
      />
      <PropertyTourActionModal visible={showTour} mlsnum={mlsnum} onClose={() => setShowTour(false)} />
      <PropertyMoreInfoActionModal visible={showMoreInfo} mlsnum={mlsnum} onClose={() => setShowMoreInfo(false)} />
    </>
  );
};

function preApprovalClickAction(mlsnum, dispatch) {
  APIService.post(`/properties/${mlsnum}/financing/`)
    .then((response) => {
      if (response.data.success) {
        dispatch(fetchChatAction(mlsnum));
        dispatch(toggleChatAction('open'));
        return;
      }

      Utils.showErrorToast(response.data.message || 'Sorry, something went wrong');
    })
    .catch((err) => Utils.showErrorToast(err.message));
}
