import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import userReducer from './user/reducer';
import propertyReducer from './property/reducer';
import chatReducer from './chat/reducer';
import activityReducer from './activity/reducer';

export default function rootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    user: userReducer,
    property: propertyReducer,
    chat: chatReducer,
    activity: activityReducer,
  });
}
