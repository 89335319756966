export const GoogleMapTypes = {
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  HYBRID: 'hybrid',
  TERRAIN: 'terrain',
};

export const MapFilterTabs = {
  NEW: { label: 'New', path: 'new', apiEndpoint: 'new', tabId: 'new' },
  FAV: { label: 'Favs', path: 'likes', apiEndpoint: 'favs', tabId: 'favs' },
  REJECT: { label: 'Reject', path: 'rejects', apiEndpoint: 'rejects', tabId: 'rejects' },
  OPEN: { label: 'Open Houses', path: 'open-houses', apiEndpoint: 'open-houses', tabId: 'open' },
};

export const DefaultMapZoom = 9;
