import React from 'react';
import { SchoolInfoRow } from '.';

import { FlexCol } from '../FlexCol';

export const SchoolInfoSectionForm = ({ data = {}, advanced = false }) => {
  const list = data.filter((item) => (advanced ? item : !item.advanced));
  return (
    <FlexCol className="w-full lg:flex-row">
      {list.map((section, index) => {
        return (
          <FlexCol key={index} className="w-full">
            <SchoolInfoRow section={section} />
          </FlexCol>
        );
      })}
    </FlexCol>
  );
};
