import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { syncServerStatusAction } from '_app/store/user/action';

const Maintenance = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(syncServerStatusAction());
  }, []);
  return (
    <div className="flex flex-1 flex-col justify-center items-center">
      <div className="text-primary2 text-lg font-primary-bold text-center">Houston Properties is currently down for maintenance.</div>
      <div className="text-primary2 text-center font-primary-thin mt-4">This page will reload automatically.</div>
    </div>
  );
};

export default Maintenance;
