import moment from 'moment';
import React from 'react';

export const ChatMessageItem = ({ item }) => {
  const { sender, message, datetime } = item;
  return (
    <div className={`m-2 flex flex-row items-start ${!sender.is_agent ? 'justify-end' : 'justify-start'}`}>
      {sender.is_agent && <img src={sender.photo} className="w-12 h-12 rounded-full" />}
      {sender.is_agent && (
        <div className="w-2 mt-2 overflow-hidden inline-block flex-shrink-0">
          <div className="h-4 bg-green-600 -rotate-45 transform origin-top-right" />
        </div>
      )}
      <div
        className={`px-2 py-1.5 rounded bg-gray-100 flex-flex-col ${
          sender.is_agent ? ' border-l-2 border-primary-green' : ' border-r-2 border-primary-red text-right'
        }`}>
        <div className="text-xs text-primary3 font-primary-bold">
          <span>{sender.name}</span>
          <span className="text-black"> at {moment(datetime).fromNow()}</span>
        </div>
        <div className="text-sm">{message}</div>
      </div>
      {!sender.is_agent && (
        <div className="w-2 mt-2 overflow-hidden inline-block flex-shrink-0">
          <div className="h-4 bg-primary-red rotate-45 transform origin-top-left"></div>
        </div>
      )}
      {!sender.is_agent && sender.photo && <img src={sender.photo} className="w-12 h-12 rounded-full flex-shrink-0" />}
      {!sender.is_agent && !sender.photo && (
        <div className="w-12 h-12 rounded-full border-2 flex items-center justify-center flex-shrink-0">
          <span className="text-2xl text-gray-600 font-extrabold">{sender.name[0].toUpperCase()}</span>
        </div>
      )}
    </div>
  );
};
