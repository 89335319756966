import React from 'react';
import { FlexRow, Label } from '..';

import { FlexCol } from '../FlexCol';
import { PropertyInfoRow } from './PropertyInfoRow';

export const PropertyInfoSectionForm = ({ data = {}, advanced = false }) => {
  const list = data.filter((item) => (advanced ? item : !item.advanced));
  return (
    <FlexCol className="w-full">
      <FlexCol className="lg:flex-row">
        <FlexCol className="w-full">
          {list.slice(0, list.length / 2 + 1).map((section, index) => {
            return <PropertyInfoRow key={index} className="w-full" section={section} />;
          })}
        </FlexCol>
        <FlexCol className="w-full">
          {list.slice(list.length / 2 + 1, list.length).map((section, index) => {
            return <PropertyInfoRow key={index} className="w-full" section={section} />;
          })}
        </FlexCol>
      </FlexCol>
      <FlexRow className="justify-end">
        <Label label="*If checkbox is selected, null values will be excluded." className="text-gray-500" />
      </FlexRow>
    </FlexCol>
  );
};
