import { handleActions } from 'redux-actions';
import produce from 'immer';
import _ from 'lodash';

import { FAIL, START, SUCCESS } from '../common';
import { FETCH_CHAT, SEND_CHAT, TOGGLE_CHAT } from './action';

const initialState = {
  loading: false,
  success: false,
  items: [],
  error: null,
  mlsnum: null,
  chatVisible: false,
};

export default handleActions(
  {
    [TOGGLE_CHAT]: (state, { payload }) =>
      produce(state, (draft) => {
        if (payload === 'open') {
          draft.chatVisible = true;
        } else if (payload === 'close') {
          draft.chatVisible = false;
        } else {
          draft.chatVisible = !state.chatVisible;
        }
      }),
    [FETCH_CHAT + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
        draft.items = [];
        draft.mlsnum = payload;
      }),
    [FETCH_CHAT + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.items = _.orderBy(payload.messages, ['datetime'], 'asc');
        draft.error = null;
      }),
    [FETCH_CHAT + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),
    [SEND_CHAT + START]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.items = [...state.items, payload];
      }),
    [SEND_CHAT + SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.items = state.items;
      }),
    [SEND_CHAT + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.error = payload;
        draft.items.pop();
      }),
  },
  initialState,
);
