import React, { useCallback, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import Constants from '_app/constants';
import { MapControls } from './MapControls';
import { DrawingPanel } from './DrawingPanel';
import { Marker } from './Marker';
import Utils from '_app/utils';

import { getPropertySelector } from '_app/store/property/selector';
import {
  clearMapAction,
  fetchPropertyAction,
  preSelectPropertyAction,
  setMapDrawModeAction,
  setMapReadyAction,
  updateMapCenterAction,
  updateMapZoomAction,
  updatePropertyFilterAction,
} from '_app/store/property/action';
import { Env } from '_app/config';
import { userSelector } from '_app/store/user/selector';

let map = null;
let maps = null;
let polygons = [];

export const MapBoard = ({ onClickMarker = () => {}, toggleList = false }) => {
  const dispatch = useDispatch();
  const [mapType, setMapType] = useState(Constants.GoogleMapTypes.ROADMAP);
  const { search_polygons } = useSelector(userSelector);
  const { items, preSelected, mapCenter, mapZoom, isMapDrawing, filter, mapReady, loading, success, toggleSearchFilter } =
    useSelector(getPropertySelector);
  const { searchAsMove, bounds } = filter;

  useEffect(() => {
    if (mapReady && !!map && !!maps && !loading && success && items.length && !toggleList) {
      const coords = items.map(({ latitude, longitude }) => new maps.LatLng(latitude, longitude));
      Utils.fitMapToBounds(maps, map, coords);
    }
  }, [loading, success, toggleList]);

  useEffect(() => {
    if (!map) {
      return;
    }
    if (isMapDrawing) {
      map.setOptions({ draggableCursor: 'crosshair' });
    } else {
      map.setOptions({ draggableCursor: '' });
    }
  }, [isMapDrawing]);

  useEffect(() => {
    if (mapReady && maps && search_polygons && search_polygons.polygons && search_polygons.polygons.length && !toggleSearchFilter) {
      cleanPolygon();
      const coordsArray = [];
      setTimeout(() => {
        search_polygons.polygons.forEach((search_polygon) => {
          const coords = search_polygon.latitude.map((lat, index) => new maps.LatLng(lat, search_polygon.longitude[index]));
          coordsArray.push(coords);
          drawFinalPolygon(new maps.MVCArray(coords), false);
        });
        dispatch(updatePropertyFilterAction({ key: 'bounds', value: coordsArray, shouldUpdateApi: false }));
      });
    }
  }, [search_polygons, mapReady, toggleSearchFilter]);

  const handleApiLoaded = useCallback(
    (mapRef, mapsRef) => {
      map = mapRef;
      maps = mapsRef;
      dispatch(setMapReadyAction());
    },
    [dispatch],
  );

  const onClickMapMarker = useCallback(
    (item) => {
      if (!preSelected || preSelected.mlsnum !== item.mlsnum) {
        dispatch(preSelectPropertyAction({ ...item, shouldScrollToItem: true }));
        if (isMobile) {
          onClickMarker(item);
        }
      } else {
        onClickMarker(item);
      }
    },
    [dispatch, onClickMarker, preSelected],
  );

  const cleanPolygon = () => {
    if (polygons.length) {
      polygons.forEach((polygon) => {
        polygon.setMap(null);
        polygon = null;
      });
    }
  };

  const clearMap = () => {
    cleanPolygon();
    dispatch(clearMapAction());
  };

  const drawFinalPolygon = (coords, shouldUpdateApi = true) => {
    if (!map || !maps) {
      return;
    }
    // cleanPolygon();
    polygons.push(Utils.createPolygon(map, maps, coords, true));
    dispatch(setMapDrawModeAction(false));
    if (shouldUpdateApi) {
      dispatch(updatePropertyFilterAction({ key: 'bounds', value: [...bounds, coords.getArray()], shouldUpdateApi }));
    }
    // dispatch(fetchPropertyAction());
    // Utils.fitMapToBounds(maps, map, coords.getArray());
  };

  const onMapChange = ({ center, zoom }) => {
    dispatch(updateMapCenterAction({ location: center, zoom }));
    if (polygons.length) {
      return;
    }
  };

  return (
    <div className="flex-1 relative">
      <GoogleMapReact
        bootstrapURLKeys={{ key: Env.GOOGLE_API_KEY }}
        yesIWantToUseGoogleMapApiInternals={true}
        center={mapCenter}
        zoom={mapZoom}
        options={() => ({
          panControl: false,
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          mapTypeId: mapType,
        })}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onChange={(data) => onMapChange(data)}
        // onZoomAnimationEnd={(level) => dispatch(updateMapZoomAction(level))}
      >
        {items.map((item) => (
          <Marker
            key={item.mlsnum}
            lat={item.latitude}
            lng={item.longitude}
            mlsnum={item.mlsnum}
            preSelected={preSelected && preSelected.mlsnum === item.mlsnum}
            price={item.price}
            onClick={() => onClickMapMarker(item)}
          />
        ))}
        {mapReady && isMapDrawing && <DrawingPanel map={map} maps={maps} onStartDrawing={() => {}} onCompleteDrawing={drawFinalPolygon} />}
      </GoogleMapReact>
      {!toggleList && (
        <MapControls
          searchAsMove={searchAsMove}
          enableMapMoveSearch={() => dispatch(updatePropertyFilterAction({ key: 'searchAsMove', value: !searchAsMove }))}
          onChangeMapType={setMapType}
          loading={loading}
          onZoomIn={() => dispatch(updateMapZoomAction(mapZoom < 22 ? mapZoom + 1 : mapZoom))}
          onZoomOut={() => dispatch(updateMapZoomAction(mapZoom > 3 ? mapZoom - 1 : mapZoom))}
          onClear={clearMap}
          searchCurrentBounds={() => dispatch(fetchPropertyAction())}
        />
      )}
    </div>
  );
};
