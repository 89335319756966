import { createAction } from 'redux-actions';
import { START } from '../common';

export const FETCH_ACTIVITY = 'FETCH_ACTIVITY';
export const FETCH_UPCOMINGS = 'FETCH_UPCOMINGS';
export const RESERVE_OPEN_HOUSE = 'RESERVE_OPEN_HOUSE';

export const fetchActivityAction = createAction(FETCH_ACTIVITY + START);
export const fetchUpcomingsAction = createAction(FETCH_UPCOMINGS + START);
export const reserveOpenHouseAction = createAction(RESERVE_OPEN_HOUSE + START);
