import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FlexRow } from './FlexRow';
import { Label } from './Label';

export const CheckBox = ({ item = {}, checked, label = '', className = '', labelClassName = '', inputClassName = '', onClick = () => {} }) => (
  <FlexRow className={'items-center justify-between cursor-pointer ' + className} onClick={() => onClick(!item.value)}>
    {!!label && <Label label={label} className={labelClassName} />}
    <div
      className={
        'w-4 h-4 cursor-pointer border-primary2 border flex justify-center items-center rounded ' +
        inputClassName +
        ` ${checked ? 'bg-primary2' : ''}`
      }>
      {checked && <FontAwesomeIcon icon={['fas', 'check']} className="text-xs text-white" />}
    </div>
  </FlexRow>
);
