import { handleActions } from 'redux-actions';
import produce from 'immer';

import { FAIL, START, SUCCESS } from '../common';
import { FETCH_PROFILE, SET_PREVIOUS_ROUTE, SET_PUBLIC_ID, TOGGLE_NAVBAR } from './action';

const initialState = {
  loading: false,
  success: false,
  error: null,
  user: {},
  publicId: null,
  showNavBar: false,
  previousRoute: null, // previous url for maintenance redirect
};

export default handleActions(
  {
    [FETCH_PROFILE + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.error = null;
      }),
    [FETCH_PROFILE + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.error = null;
        draft.user = payload;
      }),
    [FETCH_PROFILE + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),
    [SET_PUBLIC_ID]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.publicId = payload;
      }),
    [SET_PREVIOUS_ROUTE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.previousRoute = payload;
      }),
    [TOGGLE_NAVBAR]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.showNavBar = payload;
      }),
  },
  initialState,
);
