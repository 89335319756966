import React from 'react';
import _ from 'lodash';

import { FlexCol } from '../FlexCol';
import { useDispatch } from 'react-redux';
import { handleSearchOptionChangeAction } from '_app/store/property/action';

const removeIncludedValues = (selectedValues = [], choiceValues = []) => {
  return selectedValues ? selectedValues.filter((item) => !choiceValues.map(({ value }) => value).includes(item)) : [];
};

const getSelectValues = (id) => {
  const select = document.getElementById(id);
  let result = [];
  let options = select && select.options;
  let opt;

  for (var i = 0, iLen = options.length; i < iLen; i++) {
    opt = options[i];

    if (opt.selected) {
      result.push(opt.value || opt.text);
    }
  }
  return result.map((value) => Number(value));
};

const generateNewChoiceItem = (item) => {
  const { name: id, value, choices } = item;
  const ids = getSelectValues(id);
  const newValues = _.uniq([...removeIncludedValues(value, choices), ...ids]);
  return { ...item, value: newValues };
};

const ALL_SELECTOR = { label: 'All', value: 'all' };

export const MultiListSelector = ({ item }) => {
  const dispatch = useDispatch();
  const onChangeValue = (sItem) => {
    const newItem = generateNewChoiceItem(sItem);
    const newValues = newItem.value.filter((sItem) => !!sItem);
    dispatch(handleSearchOptionChangeAction({ ...newItem, value: newValues.length ? newValues : null }));
  };
  return (
    <FlexCol className="border mb-2 rounded sm:rounded-lg shadow overflow-y-auto">
      <select
        id={item.name}
        className="outline-none w-full h-6 sm:h-32 rounded-none px-2 sm:px-0"
        multiple
        value={item.value || ['all']}
        onChange={() => onChangeValue(item)}>
        {[ALL_SELECTOR, ...item.choices].map(({ label, value }, sIndex) => (
          <option key={sIndex} value={value} className="pt-1.5 px-2 border-b text-sm font-primary-bold text-gray-600 outline-none">
            {label}
          </option>
        ))}
      </select>
    </FlexCol>
  );
};
