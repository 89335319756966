export const priceFormater = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num;
};

export const generateMapSearchParams = (tab, bounds, enableBounds, page = 1) => {
  let query = `${tab}/?page=${page}&limit=50`;
  if (!enableBounds) {
    return query;
  }
  if (bounds) {
    if (bounds instanceof Array) {
      const lats = bounds.map((coord) => coord.lat());
      const longs = bounds.map((coord) => coord.lng());
      const newLats = lats[0] === lats[lats.length - 1] ? lats : [...lats, lats[0]];
      const newLongs = longs[0] === longs[longs.length - 1] ? longs : [...longs, longs[0]];
      query = query + `&lat=${newLats.join('|')}&long=${newLongs.join('|')}`;
    } else {
      const lats = Object.keys(bounds).map((key) => bounds[key].lat);
      const longs = Object.keys(bounds).map((key) => bounds[key].lng);
      const newLats = lats[0] === lats[lats.length - 1] ? lats : [...lats, lats[0]];
      const newLongs = longs[0] === longs[longs.length - 1] ? longs : [...longs, longs[0]];
      query = query + `&lat=${newLats.join('|')}&long=${newLongs.join('|')}`;
    }
  }
  return query;
};

export const generateTabCountParams = (bounds, enableBounds) => {
  let query = '';
  if (!enableBounds) {
    return query;
  }
  if (bounds) {
    if (bounds instanceof Array) {
      const lats = bounds.map((coord) => coord.lat());
      const longs = bounds.map((coord) => coord.lng());
      query = query + `?lat=${[...lats, lats[0]].join('|')}&long=${[...longs, longs[0]].join('|')}`;
    } else {
      const lats = Object.keys(bounds).map((key) => bounds[key].lat);
      const longs = Object.keys(bounds).map((key) => bounds[key].lng);
      query = query + `&lat=${[...lats, lats[0]].join('|')}&long=${[...longs, longs[0]].join('|')}`;
    }
  }
  return query;
};

export const generateMapPolygonUpdateFormData = (bounds = []) => {
  const body = [];
  bounds.forEach((bound) => {
    const lats = bound.map((coord) => coord.lat());
    const longs = bound.map((coord) => coord.lng());
    const latitude = lats[0] === lats[lats.length - 1] ? lats : [...lats, lats[0]];
    const longitude = longs[0] === longs[longs.length - 1] ? longs : [...longs, longs[0]];
    body.push({ latitude, longitude });
  });
  return { polygons: body };
};
