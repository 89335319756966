import React from 'react';

export const NotificationComponent = ({ text, undoAction = () => {} }) => {
  return (
    <span className="leading-7">
      {text}
      <span className="px-2 py-1 rounded-md bg-white text-primary3 text-sm" onClick={undoAction}>
        Undo
      </span>
    </span>
  );
};
