import React from 'react';
import Constants from '_app/constants';
import { Label } from '.';
import { Button } from './Button';

export const MapControls = ({
  searchAsMove = true,
  onChangeMapType = () => {},
  searchCurrentBounds = () => {},
  onZoomIn = () => {},
  onZoomOut = () => {},
  onClear = () => {},
  loading = false,
}) => {
  return (
    <>
      <div className="absolute top-2 right-2 flex flex-row items-center">
        {!searchAsMove && (
          <Button
            label="Search Here"
            className="bg-green-500 active:bg-green-400 text-sm text-white p-1 px-3 rounded mr-2 shadow"
            onClick={searchCurrentBounds}
          />
        )}
        <select
          onChange={(e) => onChangeMapType(e.target.value)}
          className="px-2 py-1 rounded shadow outline-none border text-xl hover:bg-gray-50 cursor-pointer">
          <option value={Constants.GoogleMapTypes.ROADMAP}>Map</option>
          <option value={Constants.GoogleMapTypes.SATELLITE}>Satellite</option>
          <option value={Constants.GoogleMapTypes.HYBRID}>Hybrid</option>
        </select>
      </div>
      <div className="absolute top-2 left-2 flex flex-col shadow rounded bg-white">
        <Button label="+" className="bg-white hover:bg-gray-100 border-b h-8 w-8 justify-center items-center text-2xl" onClick={onZoomIn} />
        <Button label="−" className="bg-white hover:bg-gray-100 border-t-0.5 h-8 w-8 justify-center items-center text-2xl" onClick={onZoomOut} />
      </div>
      <div className="absolute top-20 left-2 flex flex-col shadow rounded bg-white">
        <Button
          label="Clear Map"
          className="bg-white hover:bg-gray-100 border shadow-sm justify-center items-center font-primary px-2 py-1"
          onClick={onClear}
        />
      </div>
      {loading && (
        <div className="absolute bottom-6 left-0 right-0 flex flex-row justify-center items-center">
          <Label
            label="Loading Properties..."
            className="bg-white bg-opacity-90 shadow-xl text-primary1 rounded-full border justify-center items-center font-primary-bold px-4 py-1"
            onClick={onClear}
          />
        </div>
      )}
    </>
  );
};
