import React from 'react';

import { BuyRentSelector, CommaInput, OrNotSelector, CopyIcon, PropertyTypeSelector } from '.';
import { FlexCol, FlexRow } from '..';

export const SearchSectionForm = ({ data = [] }) => {
  const saleTypeData = data.find((item) => item.name === 'search_sale_type');
  const propertyTypeData = data.find((item) => item.name === 'search_prop_type');
  const mlsTypeData = data.find((item) => item.name === 'search_mlsnum');
  const mlsConditionData = data.find((item) => item.name === 'search_mlsnum_condition');
  return (
    <FlexCol>
      <FlexRow className="items-center">
        <BuyRentSelector className="w-1/3 sm:w-1/4 sm:flex-row" item={saleTypeData} />
        <FlexCol className="mt-4 w-2/3 sm:w-3/4 sm:flex-row">
          <CommaInput item={mlsTypeData} placeholder="MLS #: Enter MLS number(s) comma separated" />
          <FlexRow className="justify-between items-start ml-1 sm:ml-2 sm:flex-col">
            <CopyIcon text={mlsTypeData.value} />
            <OrNotSelector item={mlsConditionData} />
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow className="w-full justify-end">
        <FlexRow className="w-full sm:w-3/4 items-center">
          <PropertyTypeSelector item={propertyTypeData} />
        </FlexRow>
      </FlexRow>
    </FlexCol>
  );
};
