import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LOGO from '_app/assets/images/logo.png';
import { toggleSearchFilterAction } from '_app/store/property/action';
import { publicIdSelector } from '_app/store/user/selector';

export const Logo = () => {
  const dispatch = useDispatch();
  const publicId = useSelector(publicIdSelector);
  if (publicId) {
    return (
      <Link to={`/${publicId}/listing/new/`}>
        <img src={LOGO} width="100" height="50" alt="logo" onClick={() => dispatch(toggleSearchFilterAction(false))} />
      </Link>
    );
  } else {
    return (
      <a href="https://www.houstonproperties.com/" rel="noreferrer">
        <img src={LOGO} width="100" height="50" alt="logo" />
      </a>
    );
  }
};
