import MockAdapter from 'axios-mock-adapter';

export const applyMockService = (axiosInstance) => {
  const mock = new MockAdapter(axiosInstance, { delayResponse: 1000, onNoMatch: 'passthrough' });
  // mock.onGet('/profile/').reply(503, { message: 'Houston Properties is currently down for maintenance. This page will reload automatically' });

  // mock.onGet('/search-form').reply(200, searchFormData);

  // mock.onGet('/chat').reply(200, chatData);
};
