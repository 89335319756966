import { createAction } from 'redux-actions';
import { START } from '../common';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const SET_PUBLIC_ID = 'SET_PUBLIC_ID';
export const SET_PREVIOUS_ROUTE = 'SET_PREVIOUS_ROUTE';
export const SYNC_SERVER_STATUS = 'SYNC_SERVER_STATUS';
export const TOGGLE_NAVBAR = 'TOGGLE_NAVBAR';

export const fetchProfileAction = createAction(FETCH_PROFILE + START);
export const setPublicIdAction = createAction(SET_PUBLIC_ID);
export const syncServerStatusAction = createAction(SYNC_SERVER_STATUS);
