import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { SearchPanel, ToolBar } from '_app/components';

import { fetchSearchOptionAction, toggleSearchFilterAction } from '_app/store/property/action';

const SearchForm = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSearchOptionAction());
    dispatch(toggleSearchFilterAction(true));
  }, [dispatch]);

  return (
    <div className="flex flex-1 flex-col justify-center items-center font-primary relative overflow-hidden">
      <ToolBar visibleSearch={true} visibleList={false} />
      <div className="flex-1 w-full flex h-full flex-row relative">
        <div className="absolute inset-0">
          <SearchPanel />
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
