import React from 'react';

import { FlexCol } from '../FlexCol';
import { LocationInfoRow } from './LocationInfoRow';
import { PropertyInfoRow } from './PropertyInfoRow';

export const LocationSectionForm = ({ data = {}, advanced = false }) => {
  const list = data.filter((item) => (advanced ? item : !item.advanced));
  return (
    <FlexCol className="w-full">
      <FlexCol className="lg:flex-row">
        <FlexCol className="w-full">
          {list.slice(0, list.length / 2 + 1).map((section, index) => {
            return <LocationInfoRow key={index} className="w-full" section={section} />;
          })}
        </FlexCol>
        <FlexCol className="w-full">
          {list.slice(list.length / 2 + 1, list.length).map((section, index) => {
            if (section.label === 'Street #') {
              return <PropertyInfoRow key={index} className="w-full" section={section} />;
            }
            return <LocationInfoRow key={index} className="w-full" section={section} />;
          })}
        </FlexCol>
      </FlexCol>
    </FlexCol>
  );
};
