import APIService from '_app/services/api';
import { all, fork, put, take, select } from 'redux-saga/effects';

import { FAIL, START, SUCCESS } from '../common';
import { FETCH_CHAT, SEND_CHAT } from './action';
import { chatSelector } from './selector';
import Utils from '_app/utils';

function* fetchChat() {
  while (true) {
    const { payload } = yield take(FETCH_CHAT + START);
    try {
      const { data } = yield APIService.get('/chat/', { params: payload ? { mlsnum: payload } : null });
      yield put({ type: FETCH_CHAT + SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: FETCH_CHAT + FAIL, payload: error });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

function* sendChat() {
  while (true) {
    const { payload } = yield take(SEND_CHAT + START);
    const state = yield select();
    const { mlsnum } = chatSelector(state);
    const body = new FormData();
    body.append('message', payload.message);
    if (mlsnum) {
      body.append('mlsnum', mlsnum);
    }
    try {
      const { data } = yield APIService.post('/chat/', body);
      if (data.success) {
        yield put({ type: SEND_CHAT + SUCCESS });
      } else {
        yield put({ type: SEND_CHAT + FAIL, payload: data.message });
        Utils.showErrorToast(data.message);
      }
    } catch (error) {
      yield put({ type: SEND_CHAT + FAIL, payload: error });
      Utils.showErrorToast(Utils.getError(error).message);
    }
  }
}

export default function* sagas() {
  yield all([fork(fetchChat), fork(sendChat)]);
}
