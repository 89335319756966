import React from 'react';

import { OrNotSelector } from '.';
import { FlexCol, Label } from '..';
import { FlexRow } from '../FlexRow';
import { MultiListSelector } from './MultiLIstSelector';

export const RentalsInfoRow = ({ section, className = '' }) => {
  return (
    <FlexCol className={className + ' my-2 mx-4 items-center'}>
      <FlexRow className="items-start w-full mb-2">
        <Label label={section.label + ':'} className="text-left font-primary-bold" />
      </FlexRow>
      <FlexCol className="w-full">
        {section.items.map((item, index) => {
          if (!item.name.includes('_condition')) {
            return <MultiListSelector key={index} item={item} />;
          } else if (item.name.includes('_condition')) {
            return (
              <FlexRow key={index} className="w-full justify-end">
                <OrNotSelector key={index} item={item} />
              </FlexRow>
            );
          }
        })}
      </FlexCol>
    </FlexCol>
  );
};
