import React, { useEffect, useRef } from 'react';
import Carousel from 'nuka-carousel';

import { ReactComponent as NextIcon } from '_app/assets/images/arrow_right.svg';
import { ReactComponent as PrevIcon } from '_app/assets/images/arrow_left.svg';

export const MultiImageSlider = ({ images, className = '', onClick = () => {} }) => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.handleFocus();
    }
  }, []);
  const rightButton = ({ nextSlide }) => (
    <button
      className="bg-black bg-opacity-50 rounded-full p-4 mr-4 cursor-pointer select-none"
      onClick={(e) => {
        e.stopPropagation();
        nextSlide();
      }}>
      <NextIcon width={30} height={30} className="text-white " fill="white" />
    </button>
  );
  const leftButton = ({ previousSlide }) => (
    <div
      className="bg-black bg-opacity-50 rounded-full p-4 ml-4 cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        previousSlide();
      }}>
      <PrevIcon width={30} height={30} className="text-white " fill="white" />
    </div>
  );
  return (
    <div className={className}>
      <Carousel
        ref={ref}
        slidesToShow={3}
        wrapAround={true}
        enableKeyboardControls={true}
        renderBottomCenterControls={() => null}
        renderCenterLeftControls={leftButton}
        renderCenterRightControls={rightButton}>
        {images.map((image, index) => (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick(index);
            }}
            key={index}
            className="mx-px-0.5">
            <img src={image.url} className="md:h-96 h-64 w-full object-cover" alt="property-image" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
